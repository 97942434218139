import React from "react";
import Meta from "./../components/Meta";
import LegalSection from "./../components/LegalSection";
import { useRouter } from "./../util/router";
import Footer from "./../components/Footer";


function LegalPage(props) {
  const router = useRouter();

  return (
    <>
      <Meta title="Legal" />
      <LegalSection
        bgColor="default"
        size="normal"
        bgImage=""
        bgImageOpacity={1}
        section={router.query.section}
        key={router.query.section}
      />


<Footer
                bgColor="default"
                size="medium"
                bgImage=""
                bgImageOpacity={1}
                description="Tuesday measures what you love, so you can make more of it. Every day."
                copyright={`© ${new Date().getFullYear()} Immersion Neuroscience, Inc. All Rights Reserved.`}
                logo="/images/tuesday_enterprise_logo_dark.svg"
                logoInverted="/images/tuesday_enterprise_logo_light.svg"
                sticky={true}
              />

    </>
  );
}

export default LegalPage;
