import React from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
import FeaturesSection from "./../components/FeaturesSection";
import ClientsSection from "./../components/ClientsSection";
import TestimonialsSection from "./../components/TestimonialsSection";
import TestimonialsSectionImages from "./../components/TestimonialsSectionImages";
import NewsletterSection from "./../components/NewsletterSection";
import CtaSection from "./../components/CtaSection";
import Footer from "./../components/Footer";
import ContentSectionImageRight from "./../components/ContentSectionImageRight";
import ContentSectionImageLeft from "./../components/ContentSectionImageLeft";

function IndexPage(props) {
  return (
    <>
      <Meta />
      <HeroSection
        bgColor="primary"
        size="large"
        bgImage="/images/tuesday-header-image-v2.jpg"
        bgImageOpacity={0.3}
        title="Revolutionize Wellness"
        subtitle="Tuesday creates happy, healthier people by measuring what they love."
        buttonText="Get Started"
        buttonColor="default"
        buttonPath="/pricing"
      />

<ContentSectionImageLeft
        bgColor=""
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Real-time Emotional Fitness"
        bodyText="Tuesday empowers people to take control of their wellness with daily measurements and guidance through neurological measurement. So you can support people’s emotional wellness journey and create individualized programming for success with objective metrics that matter."
        subtitle="All the features you need to move faster"
        mainImage="/images/tuesday_person_01.jpg"
        buttonText="Get Started"
        buttonColor="secondary"
        buttonPath="/pricing"
      />

<ContentSectionImageRight
        bgColor=""
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Know when to Intervene"
        bodyText="Users can opt-in to share their data, giving you a real-time, anonymized report to know when things are going well, or when you might need to step in. With Tuesday, you can understand how users are doing day-to-day and predict future mood states that might require intervention."
        subtitle="All the features you need to move faster"
        mainImage="/images/group_employees.jpg"
        buttonText="Get Started"
        buttonColor="secondary"
        buttonPath="/pricing"
      />

<ContentSectionImageLeft
        bgColor=""
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Privacy First"
        bodyText="All data is anonymized and shown in aggregate, so your users can have confidence Tuesday is designed to help, not spy. Tuesday users who share data report seeing increased support from their leaders and social connections, creating more happy days."
        subtitle="All the features you need to move faster"
        mainImage="/images/tuesday_two_people.jpg"
        buttonText="Get Started"
        buttonColor="secondary"
        buttonPath="/pricing"
      />

<ContentSectionImageRight
        bgColor=""
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Simple to Deploy"
        bodyText="Users to sign up for the Tuesday app and sync with their smartwatch. They can choose to share data and you see the real-time updates on your mobile dashboard. Get wellness KPI’s at a glance or target users in low emotional fitness for timely interventions through the app or in person."
        subtitle="All the features you need to move faster"
        mainImage="/images/tuesday_girl_01-v2.jpg"
        buttonText="Get Started"
        buttonColor="secondary"
        buttonPath="/pricing"
      />


      <FeaturesSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title=""
        subtitle=""
      />
      <ClientsSection
        bgColor="light"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Best. Tuesday. Ever."
        subtitle="Join the many other companies that are using the science behind Tuesday to measure people."
      />
      <TestimonialsSectionImages
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Here's what people are saying"
        subtitle=""
      />
      <NewsletterSection
        bgColor="light"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Stay in the know"
        subtitle="Receive our latest articles and feature updates"
        buttonText="Subscribe"
        buttonColor="primary"
        inputPlaceholder="Enter your email"
        subscribedMessage="You are now subscribed!"
      />
      <CtaSection
        bgColor="primary"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Ready to get started?"
        subtitle=""
        buttonText="Get Started"
        buttonColor="default"
        buttonPath="/pricing"
      />

      <Footer
                bgColor="default"
                size="medium"
                bgImage=""
                bgImageOpacity={1}
                description="Tuesday measures what you love, so you can make more of it. Every day."
                copyright={`© ${new Date().getFullYear()} Immersion Neuroscience, Inc. All Rights Reserved.`}
                logo="./images/tuesday_enterprise_logo_dark.svg"
                logoInverted="./images/tuesday_enterprise_logo_light.svg"
                sticky={true}
              />


    </>
  );
}

export default IndexPage;
