import React from "react";
import Meta from "./../components/Meta";
import ContactSection from "./../components/ContactSection";
import Footer from "./../components/Footer";
import HeroSection2 from "./../components/HeroSection2";
import HubspotForm from "react-hubspot-form";


function ContactPage(props) {
  return (
    <>
      <Meta title="Contact" />
      <HeroSection2
        bgColor="primary"
        size="large"
        bgImage="./images/contact-header.jpg"
        bgImageOpacity={0.4}
        title="Have Questions?"
        subtitle="Drop us a note and let's schedule a time to talk.  We would love to learn more about your goals and would be happy to share a demo."
      />



      <ContactSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Contact Us"
        subtitle=""
        buttonText="Send message"
        buttonColor="primary"
        showNameField={true}
      />

<Footer
                bgColor="default"
                size="medium"
                bgImage=""
                bgImageOpacity={1}
                description="Tuesday measures what you love, so you can make more of it. Every day."
                copyright={`© ${new Date().getFullYear()} Immersion Neuroscience, Inc. All Rights Reserved.`}
                logo="./images/tuesday_enterprise_logo_dark.svg"
                logoInverted="./images/tuesday_enterprise_logo_light.svg"
                sticky={true}
              />

    </>
  );
}

export default ContactPage;
