import React, { useState, useRef } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { v4 as uuidv4 } from 'uuid';
import { useTeam, updateTeam, updateShareRequest, createShareRequest } from "../util/db";
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import AspectRatio from "./AspectRatio";
import Typography from "@material-ui/core/Typography";
import {QRCodeCanvas, QRCodeSVG} from 'qrcode.react';
import { createShareRequestInviteLink } from "../util/util";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import html2canvas from 'html2canvas';




const moment = require('moment');

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    cardContent: {
      padding: theme.spacing(3),
    },
    forceH: {
      //height: "800px",
    },
    forceScatterH: {
      //height: "800px",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
      },
      errorMsg: {
        fontWeight: "800",
        color: "#ff0000",
      },
}));


const ShareRequestForm = (props) => {
    const classes = useStyles();
    const [openCopyAlert, setOpenCopyAlert] = React.useState(false);
    const printRef = React.useRef();

    const [shareRequest, setShareRequest] = useState(props.shareRequest ? props.shareRequest : {title: '', desc: '', max_accept: 1000, start: moment().format('YYYY-MM-DDTHH:mm'), end: moment().add(1, 'h').format('YYYY-MM-DDTHH:mm'), type: 'live'});
    

    console.log(shareRequest);

    const [errorMsg, setErrorMsg] = useState('');
    const [srInviteLink, setSrInviteLink] = useState('');

    const { title, desc, start, end, max_accept } = shareRequest;

    const showCopyAlert = () => {
      setOpenCopyAlert(true);
    };
  
    const closeCopyAlert = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpenCopyAlert(false);
    };


    const getRandomPlaceholderImage = () => {
        var r = Math.floor(Math.random() * (71 - 1 + 1) + 1)
        return "https://immersion-prod-saas-docs.s3.amazonaws.com/placeholders/large-" + r + ".svg";
    }

    const handleOnSubmit = async (event) => {
        event.preventDefault();
        const values = [title, desc, start, end, max_accept]; //, start, end
        let errorMsg = '';
    
        const allFieldsFilled = values.every((field) => {
          const value = `${field}`.trim();
          return value !== '' && value !== '0';
        });
    
        if (allFieldsFilled) {
            shareRequest.title = title;
            shareRequest.desc = desc;
            shareRequest.max_accept = max_accept;

            console.log("start == " + start);
            console.log("start UTC == " + moment(start).utc().toDate());

            console.log("end == " + end);
            console.log("end UTC == " + moment(end).utc().toDate());

            shareRequest.start = moment(start).utc().toDate();
            shareRequest.end = moment(end).utc().toDate();

            var eventDurationHours = moment(end).diff(moment(start), "hours")
            console.log("eventDurationHours == " + eventDurationHours);


            if (eventDurationHours <= 8 && moment(end).unix() > moment(start).unix()) {
            if (shareRequest.id !== undefined) {
                updateShareRequest(shareRequest.id, shareRequest);
                //props.callback()
            } else {
                shareRequest["from_name"] = props.team.name ? props.team.name : "Your Friend";
                shareRequest["logo"] = props.team.logo ? props.team.logo : "https://logo.immersion.cx/getimmersion.com";
                shareRequest["owner"] = props.team.id;

                shareRequest["completed_count"] = 0;
                shareRequest["accept_count"] = 0;
                shareRequest["deny_count"] = 0;
                shareRequest["max_accept"] = 1000;
                shareRequest["has_reward"] = false;

                if (shareRequest.type === "live") {
                    shareRequest["image_url"] = getRandomPlaceholderImage();
                }

                var result = await createShareRequest(shareRequest);
                console.log("SAVED share request: " + result);

                setShareRequest(result);

                createShareLink(result.id);


                //props.callback()
            }
            } else {
              errorMsg = 'A Share Request cannot be longer than 8 hours and must end after it begins.';
            }
        } else {
          errorMsg = 'Please fill out all the fields.';
        }
        setErrorMsg(errorMsg);
      };


      const createShareLink = async (srId) => {
        console.log("createShareLink: " + srId);
        const inviteLink = await createShareRequestInviteLink(srId);
        setSrInviteLink(inviteLink)
        shareRequest.invite_link = inviteLink;
        updateShareRequest(srId, shareRequest);
        setShareRequest(shareRequest);
      }


      const handleInputChange = (event) => {
        console.log(event);
        const { id, value } = event.target;
        switch (id) {
          case 'max_accept':
            if (value === '' || parseInt(value) === +value) {
                setShareRequest((prevState) => ({
                ...prevState,
                [id]: value
              }));
            }
            break;
          case 'price':
            if (value === '' || value.match(/^\d{1,}(\.\d{0,2})?$/)) {
                setShareRequest((prevState) => ({
                ...prevState,
                [id]: value
              }));
            }
            break;
          default:
            console.log(id + " -> " + value);
            setShareRequest((prevState) => ({
              ...prevState,
              [id]: value
            }));
        }
      };
    
      const handleCopyLink = () => {
    
        //const link = `https://wondr.cx/join/${team.teamid}`;
        if (shareRequest && shareRequest.invite_link) {
          showCopyAlert();
          console.log(`copy to paste buffer: ${shareRequest.invite_link}`);
          navigator.clipboard.writeText(shareRequest.invite_link);
          
        }
      }

      const handleDownloadQR = async () => {
        console.log("handleDownloadQR");
        const element = printRef.current;
        const canvas = await html2canvas(element);

        const data = canvas.toDataURL('image/jpg');
        const link = document.createElement('a');

        if (typeof link.download === 'string') {
          link.href = data;
          link.download = `tuesday_share_request_qr_${moment().format('yyyyMMDD_hms')}.jpg`;

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          window.open(data);
        }

      };

      return (
        <div className="main-form">
          {errorMsg && <p className={classes.errorMsg}>{errorMsg}</p>}

          <Snackbar open={openCopyAlert} autoHideDuration={6000} onClose={closeCopyAlert}>
            <Alert onClose={closeCopyAlert} severity="success">
              Copied to clipboard
            </Alert>
          </Snackbar>


          <form noValidate=''>

          <Grid container spacing={5}>
              <Grid item xs={7} >


          <Grid
            container
            direction='column'
            justifyContent='center'
            alignItems='center'
            spacing={3}
          >
            <Grid container item>
              <TextField
                require={true}
                id='title'
                label='Title'
                variant='outlined'
                autoComplete='new-title'
                fullWidth
                value={shareRequest.title}
                onChange={(event) => handleInputChange(event)}
              />
            </Grid>
            
            <Grid container item>
              <TextField
                required
                id='desc'
                label='Description'
                fullWidth
                multiline
                minRows={4}
                value={shareRequest.desc}
                variant='outlined'
                onChange={(e) => handleInputChange(e)}
              />
            </Grid>

            <Grid container item>
              <TextField
                required
                id='max_accept'
                label='Max number of participants'
                fullWidth
                value={shareRequest.max_accept}
                variant='outlined'
                onChange={(e) => handleInputChange(e)}
              />
            </Grid>

            <Grid container item>
            <TextField
                id="start"
                label="Start"
                type="datetime-local"
                defaultValue={shareRequest.start != undefined ? shareRequest.start : moment().format('YYYY-MM-DDTHH:mm')}  //"2017-05-24T22:30"
                className={classes.textField}
                InputLabelProps={{
                shrink: true,
                }}
                variant='outlined'
                onChange={(e) => handleInputChange(e)}
            />
            
            <TextField
                id="end"
                label="End"
                type="datetime-local"
                defaultValue={shareRequest.end !== undefined ? shareRequest.end : moment().add(1, 'h').format('YYYY-MM-DDTHH:mm')} //"2017-05-24T10:30"
                className={classes.textField}
                InputLabelProps={{
                shrink: true,
                }}
                variant='outlined'
                onChange={(e) => handleInputChange(e)}
            />
            </Grid>
            

           
            <Grid container item>
              <Button
                variant='contained'
                fullWidth
                color='primary'
                onClick={handleOnSubmit}
              >
                Save
              </Button>    
            </Grid>
          </Grid>


          </Grid>
          <Grid item xs={5} justifyContent='flex-start' alignContent='flex-start' alignItems='flex-start'>
          {/* side bar */}
          
          {shareRequest && shareRequest.invite_link != undefined && 
          <Box py={2} px={3} >
                  
                <div ref={printRef} >
                  <AspectRatio ratio={4 / 4}>
                  <QRCodeCanvas value="https://reactjs.org/" size={300} onClick={handleDownloadQR}/>
                    </AspectRatio>
                    </div>

                    

                <br/>

                <Typography variant={`h5`} color="secondary" gutterBottom={false} >
                Invite Link:
                </Typography>
                <Typography variant={`body1`} color="primary" gutterBottom={false} >
                 {shareRequest.invite_link}  
                 <Button variant="default" color="secondary" size="large" onClick={handleCopyLink} >
                  <FileCopyIcon />
                   </Button>
                </Typography>
            </Box>
          }

          </Grid>

          </Grid>


        </form>


         {/* <Form onSubmit={handleOnSubmit}>
            <Form.Group controlId="name">
              <Form.Label>Title</Form.Label>
              <Form.Control
                className="input-control"
                type="text"
                name="title"
                value={title}
                placeholder="Enter name for experience"
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="desc">
              <Form.Label>Description</Form.Label>
              <Form.Control
                className="input-control"
                type="text"
                name="desc"
                value={desc}
                placeholder="Enter description of experience"
                onChange={handleInputChange}
              />
            </Form.Group>
            
            <Button variant="primary" type="submit" className="submit-btn">
              Submit
            </Button>
      </Form>*/}
        </div>
      );
    };
    
    export default ShareRequestForm;