import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useForm } from "react-hook-form";
import { useAuth } from "../util/auth";
import { DataGrid } from '@material-ui/data-grid';
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TimelineIcon from '@material-ui/icons/Timeline';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import StarIcon from '@material-ui/icons/Star';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTeam, updateTeam, useInterventionsByTeam, updateIntervention } from "../util/db";
import { Link, useRouter } from "../util/router";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinkIcon from '@material-ui/icons/Link';
import VideocamIcon from '@material-ui/icons/Videocam';
import WebIcon from '@material-ui/icons/Web';
import SpeakerIcon from '@material-ui/icons/Speaker';
import DescriptionIcon from '@material-ui/icons/Description';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';


const moment = require('moment');

function SettingsInterventions(props) {
  const auth = useAuth();
  const router = useRouter();

  const [pending, setPending] = useState(false);

  const { data: results, status: resultsStatus, error: resultsError, } = useInterventionsByTeam(props.teamid);

console.log("interventions!");

  const renderDetailsButton = (params) => {
    return (
      <>
            <IconButton
                size="small"
                style={{ marginLeft: 16 }}
                onClick={() => {
                    const reportPids = new Array()
                    reportPids.push(params.row.participant_id)

                    const reportPCodes = new Array()
                    reportPCodes.push(params.row.participant_code)

                    router.push( {pathname: `/report/${params.row.site_id}`, state: { pid: reportPids, pcode: reportPCodes, docid: params.row.id, group: false, pidgroup: []  }});
                }}
            >
                <EditIcon />
            </IconButton>
  
            
  
        <IconButton
                size="small"
                style={{ marginLeft: 16 }}
                onClick={() => {
                    console.log(params.row.id)
                    handleClickOpen(params.row.id);
                }}
            >
                <DeleteIcon />
            </IconButton>
        </>
    )
  }
  
  const [openAlert, setOpenAlert] = useState(false);
  const [deleteItem, setDeleteItem] = useState();
  const [selectionModel, setSelectionModel] = useState([]);

  const selectionReady = selectionModel && selectionModel.length > 0;


  const handleClickOpen = id => {
    setDeleteItem(id)
    setOpenAlert(true);
  };

  const handleClose = () => {
    setDeleteItem(null)
    setOpenAlert(false);
  };

  const handleDelete = () => {
    //do delete
    updateIntervention(deleteItem, {active: false} )
    setDeleteItem(null)
    setOpenAlert(false);
  };


  //const renderDetailsButton = (params) => {
  //  return (
  //    <>

  const formatLink = (params) => {
      return (
        <>

          {params.value === 'link' && <Tooltip title="Link"><LinkIcon /></Tooltip> }

          {params.value === 'website' && <Tooltip title="Website"><WebIcon /></Tooltip> }

          {params.value === 'video' && <><Tooltip title="Video"><VideocamIcon /></Tooltip></> }

          {params.value === 'text' && <><Tooltip title="Text"><DescriptionIcon /></Tooltip></> }

          {params.value === 'audio' && <><Tooltip title="Audio"><SpeakerIcon /></Tooltip></> }

        </>   
      );
  }


  const columns = [
    { field: 'id', headerName: 'ID', width: 100, hide: true},
    {
      field: 'type',
      headerName: 'Type',
      width: 25,
      flex: 0.7,
      editable: false,
      align: 'center',
      renderCell: formatLink,
      disableClickEventBubbling: true,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      flex: 1,
      editable: false,
    },
    {
      field: 'link',
      headerName: 'Link',
      width: 175,
      flex: 2,
      editable: false,
    },

   
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 200,
      flex: 1.5,
      editable: false,
      valueFormatter: params => 
         !!params?.value ? moment(params?.value.toDate()).format("MMM D, YYYY hh:mm:ss A") : "--",
    },

    {
      field: 'col6',
      headerName: 'Actions',
      width: 150,
      flex: 1,
      renderCell: renderDetailsButton,
      disableClickEventBubbling: true,
  },
  ];
  




  return (

      <>
      <Grid container={true} spacing={2}>
      <Grid item={true} xs={12} align='right'>
        <Button variant="contained" color="secondary">Add New Intervention</Button>
      </Grid>
        <Grid item={true} xs={12}>
          
        {results && 
          <div style={{ height: 500, width: '100%', background: "#fff" }}>
                <DataGrid
                  autoHeight
                  bgColor="#fff"
                  rows={results}
                  columns={columns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  onSelectionModelChange={(ids) => {
                    console.log(selectionModel)
                    console.log(ids)
                    setSelectionModel(ids);
                    console.log(selectionModel)
                  }}
                  //selectionModel={selectionModel}
                  //checkboxSelection
                  disableSelectionOnClick
                />
          </div>
          }



        </Grid>
      </Grid>


        <Dialog
        open={openAlert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">
          {"Delete this Intervention?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            By clicking 'Agree' below, you will delete this intervention.  This will permanently remove all data for this intervention from the database.  Do you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleDelete} autoFocus>
            Agree
          </Button>
        </DialogActions>
        </Dialog>

        </>

  );
}

export default SettingsInterventions;
