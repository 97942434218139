import { useEffect, useState } from 'react';
import {makeStyles} from '@material-ui/core';
//import {useCallback} from "react";

const useStyles = makeStyles(() => ({
  //score section in analysis
  icon: {
    width: 37,
    height: 37,
    marginBottom: 3
  },
  // overview icon
  icon0: {
    width: 42,
    height: 42,
    '@media screen and (max-width: 600px)': {
      width: 35,
      height: 35
    }
  },
  // header right scrore icon
  icon1: {
    width: 24,
    height: 24,
  },
  foo: {},
  litcontainer: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    justifyItems: 'center',
    alignItems: 'center',
    //float: 'inline-end',
  },
  litScoreContainer: {
    position: 'relative',
    textAlign: 'center',
  },
  litScore: {
    fontSize: '90px', 
    color: '##000000',
    fontFamily: 'Spartan',
    margin: 0,
  },
  // pills icon
  icon2: {
    width: 20,
    height: 20,
  },
}));

function ResultIcon(props) {

  const { maxheight, maxwidth, fontsize, scoretopbuffer, experiencedata, immersionScore, safetyScore, callback } = props;
  const classes = useStyles();
  const [experienceShape, setExperienceShape] = useState('solid');
  const [experienceScore, setExperienceScore] = useState(0);
  const [experienceSafetyColor, setExperienceSafetyColor] = useState('yellow');
 
  // const iconClass = size === 1 ? classes.icon1 : size === 2 ? classes.icon2 : size === 0 ? classes.icon0 : classes.icon;

  useEffect(() => {

    const cbResult = {shape: 'solid', color: 'yellow', score: 0};

    //set immersion score
    if (immersionScore) {
      //console.log(`Found immersion score of: ${immersionScore}`);
      setExperienceScore(Math.round(immersionScore));
      cbResult.score = Math.round(immersionScore);
    } else {
      setExperienceScore(0);
    }

    //set safety color
    if (safetyScore) {
      //console.log(`Found safety score of: ${safetyScore}`);
      // meeting.safety > 50 ? "iconGreen" : meeting.safety > 25 ? "iconYellow" : "iconRed"
      if (safetyScore >= 50) {
        setExperienceSafetyColor('green');
        cbResult.color = 'green';
      } else if (safetyScore > 25 && safetyScore < 50) {
        setExperienceSafetyColor('yellow');
        cbResult.color = 'yellow';
      } else {
        setExperienceSafetyColor('red');
        cbResult.color = 'red';
      }
    } else {
      setExperienceSafetyColor('yellow');
    }

    //set shape
    if (experiencedata && experiencedata.length > 0) {
      const data = experiencedata.map(score => {
        return {immersion: score}
    })
      const totalCount = data.length;
      const halfCount = Math.floor(totalCount/2);
     // console.log(totalCount);
     // console.log(halfCount);

      let t = 0;
      let tScores = 0;
      for(let i = 0; i < data.length; i++) {
          t += i;
          tScores += data[i].immersion;
      }

      const averageAllTimes = (data.length/2)/2;
      const averageAllScores = tScores / data.length;
      //console.log(`Averages time: ${t} ${averageAllTimes}  scores: ${averageAllScores}`);

      let partOneASum = 0;
      let partOneBSum = 0;
      let partTwoASum = 0;
      let partTwoBSum = 0;

      for(let i = 0; i < data.length; i++) {
        data[i]['a'] = (i+1 - averageAllTimes) * (data[i].immersion - averageAllScores);
        data[i]['b'] = Math.pow((i+1 - averageAllTimes),2);

        if (i<=halfCount) {
          partOneASum += data[i]['a'];
          partOneBSum += data[i]['b'];
        } else {
          partTwoASum += data[i]['a'];
          partTwoBSum += data[i]['b'];
        }
      }

      //console.log(data);

      const sectionOne = (partOneASum/partOneBSum);
      const sectionTwo = (partTwoASum/partTwoBSum);
      const SLOPE_CONSTANT = [-0.02, 0.02];  

      //console.log(`RESULT: ${sectionOne}   ${sectionTwo}`);

      if (  ((SLOPE_CONSTANT[0] <= sectionOne) && (sectionOne < SLOPE_CONSTANT[1])) &&
            ((SLOPE_CONSTANT[0] <= sectionTwo) && (sectionTwo < SLOPE_CONSTANT[1]))
          ) {
            //i am flat
            setExperienceShape('flat');
            console.log("i am flat");
            cbResult.shape = 'flat';

      } else if ( (SLOPE_CONSTANT[0] <= sectionOne) && (sectionTwo >= sectionOne) && (sectionTwo > SLOPE_CONSTANT[1]) ) {
            //i am up/rising
            setExperienceShape('up');
            console.log("i am up");
            cbResult.shape = 'up';

      } else if ( (sectionOne < SLOPE_CONSTANT[1]) && (sectionTwo <= sectionOne) && (sectionTwo < SLOPE_CONSTANT[0]) ) {
            //i am down/dropping
            setExperienceShape('down');
            console.log("i am down");
            cbResult.shape = 'down';

      } else if ( (sectionOne > SLOPE_CONSTANT[1]) && (sectionTwo < SLOPE_CONSTANT[1]) ) {
        //i am mountain
        setExperienceShape('mtn');
        console.log("i am mtn");
        cbResult.shape = 'mtn';

      } else if ( (sectionOne < SLOPE_CONSTANT[0]) && (sectionTwo > SLOPE_CONSTANT[0]) ) {
        //i am valley
        setExperienceShape('valley');
        console.log("i am valley");
        cbResult.shape = 'valley';

      } else {
        //i am unknown...
        setExperienceShape('flat');
        console.log("i am unmatched/flat");
        cbResult.shape = 'flat';

      }




    } else {
      setExperienceShape('solid');
    }

    //callback(cbResult);

  }, [experiencedata]);




const getImageUrl = () => {
  const imageName = experienceSafetyColor?.trim()?.toLowerCase() + '-' + experienceShape + ".png";
  return "url(\"/images/lit/" + imageName + "\")";
}


  return (
      <div id={'litscore'} className={classes.litcontainer} style={{maxHeight: maxheight, maxWidth: maxwidth, width: maxwidth, height: maxheight, backgroundImage: getImageUrl() }}>
        <div className={classes.litScoreContainer}><p className={classes.litScore} style={{fontSize: fontsize, marginTop: scoretopbuffer}}>{experienceScore}</p></div>
      </div>
  );



}
export default ResultIcon;
