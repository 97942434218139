import React from "react";
import Meta from "./../components/Meta";
import FaqSection from "./../components/FaqSection";
import CtaSection from "./../components/CtaSection";
import Footer from "./../components/Footer";
import HeroSection2 from "./../components/HeroSection2";


function FaqPage(props) {
  return (
    <>
      <Meta title="Faq" />
      <HeroSection2
        bgColor="primary"
        size="large"
        bgImage="./images/faq-header.jpg"
        bgImageOpacity={0.4}
        title="This Stuff Gets Complex"
        subtitle="We dropped a few answers to the most common questions we here below."
      />
      <FaqSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Frequently Asked Questions"
        subtitle=""
      />
      <CtaSection
        bgColor="primary"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Ready to get started?"
        subtitle=""
        buttonText="Get Started"
        buttonColor="default"
        buttonPath="/pricing"
      />

<Footer
                bgColor="default"
                size="medium"
                bgImage=""
                bgImageOpacity={1}
                description="Tuesday measures what you love, so you can make more of it. Every day."
                copyright={`© ${new Date().getFullYear()} Immersion Neuroscience, Inc. All Rights Reserved.`}
                logo="./images/tuesday_enterprise_logo_dark.svg"
                logoInverted="./images/tuesday_enterprise_logo_light.svg"
                sticky={true}
              />

    </>
  );
}

export default FaqPage;
