import React, {useState, useEffect, useReducer} from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import LinkMui from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import DashboardItems from "./DashboardItems";
import { Link, useRouter } from "./../util/router";
import queryString from 'query-string';
import { useAuth } from "./../util/auth";
import { useTeam, useShareRequestOnce } from "../util/db";
import { ShareRequestResults } from "./ShareRequestResults";
import { ShareRequestVideoResults } from "./ShareRequestVideoResults";
import { Timestamp } from "firebase/firestore";
import AppsIcon from '@material-ui/icons/Apps';
import ImageIcon from '@material-ui/icons/Image';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import ListIcon from '@material-ui/icons/List';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import VideocamIcon from '@material-ui/icons/Videocam';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import EventIcon from '@material-ui/icons/Event';

import Button from '@material-ui/core/Button';
import ScatterPlotIcon from '@material-ui/icons/ScatterPlot';
import TimelineIcon from '@material-ui/icons/Timeline';
import PieChartIcon from '@material-ui/icons/PieChart';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { TimelineChart } from './TimelineChart';
import { ScatterChart } from './ScatterChart';
import { PieChart } from './PieChart';
import CsvDownloadButton from 'react-json-to-csv'
import CustomCvsButton from "./CustomCvsButton";
import { TrendingUpRounded } from "@material-ui/icons";
import { redirectToShareResultCheckout, getStripeSessionDetails } from "./../util/stripe";


const moment = require('moment');

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(3),
  },
  forceH: {
    height: "800px",
  },
  forceScatterH: {
    minHeight: "800px",
  },
  bodyBackground: {
    backgroundImage: `url(./images/bk_high.jpg)`,
    backgroundSize: "cover",
    minHeight: "100vh",
    animation: "fadeInBodyAnimation ease 3s",
    animationIterationCount: "1",
    animationFillMode: "forwards",
  },
  root: {
    // Add bottom margin if element below
    "&:not(:last-child)": {
      marginBottom: "2rem",
    },
  },
  actionBar: {
    //boxShadow: `1px 1px 0 0 ${emphasize(theme.palette.background.paper, 0.08)}`,
    textAlign: "right",
  },
  subtitle: {
    maxWidth: 700,
    display: "inline-block",
  },
  gridItem: {
    //boxShadow: `1px 1px 0 0 ${emphasize(theme.palette.background.paper, 0.08)}`,
    textAlign: "left",
  },
  actionBar: {
    //boxShadow: `1px 1px 0 0 ${emphasize(theme.palette.background.paper, 0.08)}`,
    textAlign: "right",
  },
  buffer: {
    marginBottom: "30px",
  },
  doBlur: {
    filter: "blur('25px')",
  },
  eventImage: {
    maxWidth: "100%",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    borderRadius: "10px",
  },
  headline: {
    fontWeight: "800",
    //color: "#ffffff",
  },
}));



function ReportSection(props) {
  const classes = useStyles();
  const auth = useAuth();
  const router = useRouter();

  
  const [team, setTeam] = useState({});

  //const { data: team, status: teamStatus, error: teamError,} = useTeam(auth.user.team_id);

  const { shareId, ownerId, ...otherProps } = props;

  const { data: shareRequest, status: resultsStatus, error: resultsError, } = useShareRequestOnce(shareId);
  const [isPaid, setIsPaid] = useState(false);

  const [isPaidOVERRIDE, setIsPaidOVERRIDE] = useState(false);

  const [currentView, setCurrentView] = useState("scatter");
  const [timeLabel, setTimeLabel] = useState("Last hour");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [disableDownload, setDisableDownload] = useState(true);

  const [queryStartRange, setQueryStartRange] = useState("-1h");
  const [queryAggWindow, setQueryAggWindow] = useState("1h");
  const [legend, setLegend] = useState("Last 60 minutes");
  const [downloadData, setDownloadData] = useState([]);
  const [downloadRange, setDownloadRange] = useState([]);

  const [stripeSession, setStripeSession] = useState();

  

  useEffect(() => {
    //console.log("SHARE: " + shareId);
    //console.log("OWNER: " + ownerId);
    
    const obj = queryString.parseUrl(window.location.href);
    //console.log("obj: " + obj);
    const pOver = obj.query['p'];
    //console.log("pOver: " + pOver);

    if (!!pOver) {
      //console.log("!!pOver: " + pOver);
      //console.log("REACT_APP_REPORT_KEY: " + process.env.REACT_APP_REPORT_KEY);
      if (pOver === process.env.REACT_APP_REPORT_KEY) {
        setIsPaidOVERRIDE(true);
      }
    }

    
  },[]);
 

  //console.log("team: " + team);
  //console.log("shareRequest: " + shareRequest);
//[Log] shareRequest OwneR: e8zXYK7lZgsr7hWWFmOE (main.d9ebd7101b427df99a84.hot-update.js, line 153)


  useEffect(() => {

    if (!!shareRequest) {
        //console.log("shareRequest OwneR: " + shareRequest.owner);
        
        if (shareRequest.owner == ownerId) {
           // console.log("shareRequest: IS VALID");
            if (shareRequest.paid && shareRequest.paid == true) {
                setIsPaid(true);
            } else {
              if (isPaidOVERRIDE == true) {
                setIsPaid(true);
              } else {
                setIsPaid(false);
              }
            }


        } else {
            console.log("shareRequest: IS NOT VALID");
        }
    }
    
    
  },[shareRequest]);



  const [anchorEl, setAnchorEl] = useState(null);

  const handleDownloadDataset = (data) => {
    //console.log("handleDownloadDataset");
    setDownloadData(data);
  }

  const getFormattedDateString = (dateobject) => {
    if (dateobject && dateobject.seconds != undefined) {
    const timestamp = new Timestamp(dateobject.seconds, dateobject.nanoseconds);

    return moment(new Date(timestamp.toDate())).format("MMM D, h:mma")
    } else {
        return moment(dateobject).format("MMM D, h:mma")
    }
}
  


  const handleClose = () => {
    setAnchorEl(null);
  };
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const options = ['Last hour', 'Last day', 'Last week', 'Last month'];

  //console.log(team.team_id);

  useEffect(() => {
    //console.log("changed state");
    forceUpdate();
  },[queryStartRange, queryAggWindow]);


  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      className={classes.bodyBackground}
    >
      <Container>
        {/** Start Page Header */}
        <Grid container={true} className={classes.buffer}>
          <Grid item={true} xs={12} md={6} className={classes.gridItem} >
            <Box component="header" className={classes.root} >

              <Typography variant={`h4`} color="secondary" className={classes.headline} gutterBottom={false} >
                {shareRequest && shareRequest.from_name !== "Default" ? shareRequest.from_name : 'A Friend'}
              </Typography>
            </Box>
          </Grid>
          
        </Grid>
        {/** End Page Header */}


        {router.query.paid && auth.user.planIsActive && (
          <Box mx="auto" mb={4} maxWidth={400}>
            <Alert severity="success">
              You are now subscribed to the {auth.user.planId} plan
              <span
                role="img"
                aria-label="party"
                style={{ marginLeft: "10px" }}
              >
                🥳
              </span>
            </Alert>
          </Box>
        )}



          

          <Box>
          <Card>
            <CardContent className={classes.cardContent}>
              <Box className={classes.forceScatterH}>

              {shareRequest && shareRequest.owner == ownerId && (
                <>

                <Grid container={true} spacing={2}>
                <Grid item={true} xs={12} md={6} >
                <Typography variant="h6" color="secondary">
                {shareRequest && shareRequest.type === "recorded" && (
                  <><LiveTvIcon color="secondary" fontSize="medium" /> Recorded</>
                )}

                {shareRequest && shareRequest.type === "live" && (
                  <><EventIcon color="secondary" fontSize="medium" /> Live Event</>
                )}

                </Typography>
                </Grid>

                <Grid item={true} xs={12} md={6} alignContent="flex-end" alignItems="flex-end" justifyContent="flex-end" className={classes.actionBar}>
                <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                   {/*
                  <Button variant="contained" color="secondary" size="medium" className={classes.button} disabled={isPaid ? true : false}>
                      <MonetizationOnIcon /> 
                  </Button>
                */}

                  <Button variant="contained" color="secondary" size="medium" className={classes.button} disabled={isPaid ? false : true}>
                      <ImageIcon /> 
                  </Button>
                  

                  <CustomCvsButton variant="contained" color="secondary" data={downloadData} filename={`tuesday_event_export_${moment().format('YYYYMMDD_HH-mm-ss')}.csv`} delimiter="," disabled={isPaid ? false : true} size="medium" className={classes.button}>
                      <CloudDownloadIcon />
                  </CustomCvsButton>


                  </ButtonGroup>
                </Grid>


                  
                
                <Grid item={true} xs={12} md={4} >
                <img src={shareRequest.image_url} style={{maxWidth: "100%"}} className={classes.eventImage}/>
                </Grid>
                

                <Grid item={true} xs={12} md={8} >

                <Typography variant="h3" paragraph={true}>
                  <strong>{shareRequest.title}</strong>
                </Typography>

                <Typography variant="p" paragraph={true}>
                {shareRequest.desc}
                </Typography>

                <Typography variant="p" paragraph={true}>
                {getFormattedDateString(shareRequest.start)} → {getFormattedDateString(shareRequest.end)}
                </Typography>



                </Grid>

                
                </Grid>
                

                
                </>
                )}


                {shareRequest && shareRequest.owner == ownerId && shareRequest.type === "live" && (
                    <>
                    

                    <ShareRequestResults team={team} shareRequest={shareRequest} isPaid={isPaid} downloadCallback={handleDownloadDataset} />
                    </>
                )}

                {shareRequest && shareRequest.owner == ownerId && shareRequest.type === "recorded" && (
                    <>
                    

                    <ShareRequestVideoResults team={team} shareRequest={shareRequest} isPaid={isPaid} downloadCallback={handleDownloadDataset} />
                    </>
                )}


                {shareRequest && shareRequest.owner != ownerId && (
                    <Typography variant="h5" paragraph={true}>
                    <strong>Not a valid report request...</strong><br></br>
                  </Typography>
                )}

              </Box>
            </CardContent>
          </Card>
          </Box>
          

          


        
      </Container>
    </Section>
  );
}

export default ReportSection;
