import React, {useState, useEffect, useReducer} from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from "@material-ui/core/Typography";
import LinkMui from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from '@material-ui/core/styles';
import Section from "./Section";
import DashboardItems from "./DashboardItems";
import { Link, useRouter } from "./../util/router";
import { useAuth } from "./../util/auth";
import { useTeam, useShareRequestsByTeam, updateShareRequest } from "../util/db";
import Button from '@material-ui/core/Button';
import ScatterPlotIcon from '@material-ui/icons/ScatterPlot';
import TimelineIcon from '@material-ui/icons/Timeline';
import PieChartIcon from '@material-ui/icons/PieChart';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { TimelineChart } from './TimelineChart';
import { ScatterChart } from './ScatterChart';
import { PieChart } from './PieChart';
import CsvDownloadButton from 'react-json-to-csv'
import CustomCvsButton from "./CustomCvsButton";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AppsIcon from '@material-ui/icons/Apps';
import ListIcon from '@material-ui/icons/List';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { red, green, blue, orange } from '@material-ui/core/colors';
import VideocamIcon from '@material-ui/icons/Videocam';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ShareRequestForm from "./ShareRequestForm"
import { Timestamp } from "firebase/firestore";
import Tooltip from '@material-ui/core/Tooltip';
import { ShareRequestResults } from "./ShareRequestResults";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

const moment = require('moment');

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  cardContent: {
    padding: theme.spacing(3),
  },
  forceH: {
    //height: "800px",
  },
  forceScatterH: {
    //height: "800px",
  },
  avatarLive: {
    backgroundColor: green[500],
  },
  avatarDraft: {
    backgroundColor: blue[500],
  },
  avatarComplete: {
    backgroundColor: orange[500],
  },
  bodyBackground: {
    backgroundImage: `url(./images/bk_high.jpg)`,
    backgroundSize: "cover",
    minHeight: "100vh",
    animation: "fadeInBodyAnimation ease 3s",
    animationIterationCount: "1",
    animationFillMode: "forwards",
  },
  root: {
    // Add bottom margin if element below
    "&:not(:last-child)": {
      marginBottom: "2rem",
    },
  },
  subtitle: {
    maxWidth: 700,
    display: "inline-block",
  },
  gridItem: {
    //boxShadow: `1px 1px 0 0 ${emphasize(theme.palette.background.paper, 0.08)}`,
    textAlign: "left",
  },
  actionBar: {
    //boxShadow: `1px 1px 0 0 ${emphasize(theme.palette.background.paper, 0.08)}`,
    textAlign: "right",
  },
  buffer: {
    marginBottom: "30px",
  },
  headline: {
    fontWeight: "800",
    color: "#ffffff",
  },
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const XXXDialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, type, ...other } = props;
    //const locclasses = useStyles();

    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
           <Grid container={true} spacing={3}>
           <Grid item={true} xs={1}>
           {type==="live" ? 
         <Tooltip title="Live Event"><PeopleAltIcon color="secondary" fontSize="large" /></Tooltip>
         :
         <Tooltip title="Recorded Event"><VideocamIcon color="secondary" fontSize="large"/> </Tooltip>
        }
            </Grid> 

            <Grid item={true} xs={10}>
            <Typography variant="h6">{children}</Typography>
            </Grid> 

            <Grid item={true} xs={1}>
            {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
            </Grid>
            </Grid> 



       
        
        
      </MuiDialogTitle>
    );
  });

  

function ResearchDashboardSection(props) {
  const classes = useStyles();
  const auth = useAuth();
  const router = useRouter();

  

  const { data: team, status: teamStatus, error: teamError,} = useTeam(auth.user.team_id);
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('md');
  const [activeShareRequest, setActiveShareRequest] = useState({});
  const [shareRequestList, setShareRequestList] = useState([]);
  const [openResults, setOpenResults] = React.useState(false);
   
  const { data: results, status: resultsStatus, error: resultsError, } = useShareRequestsByTeam(team.id);
  //console.log(results);

  


  const handleEdit = (shareRequest) => {
    setActiveShareRequest(shareRequest)
    
    if (shareRequest != null && (shareRequest.status == "completed" || shareRequest.status == "active")) {
        setOpenResults(true)
    } else {
        setOpen(true);
    }
  };


//https://immersion-prod-saas-docs.s3.amazonaws.com/placeholders/large-59.svg

const getRandomPlaceholderImage = (id) => {
    var r = Math.floor(Math.random() * (71 - 1 + 1) + 1)
    var u = "https://immersion-prod-saas-docs.s3.amazonaws.com/placeholders/large-" + r + ".svg";
    updateShareRequest(id, {image_url: u});
    return u
}


const getStatus = (sharerequest) => {
    //const start_timestamp = new Timestamp(sharerequest.start.seconds, sharerequest.start.nanoseconds);
    //const end_timestamp = new Timestamp(sharerequest.end.seconds, sharerequest.end.nanoseconds);


    //return "Draft";

    const now = new Date().getTime()/1000;

    //console.log(sharerequest.title + ", " + sharerequest.start.seconds + ", " + sharerequest.end.seconds + ", " + now );


    if (sharerequest.start.seconds < now && sharerequest.end.seconds < now) {
        //completed
        if (sharerequest.status != "completed") {
            updateShareRequest(sharerequest.id, {status: "completed"});
        }
        return "Completed";
    } else if (sharerequest.start.seconds < now && sharerequest.end.seconds > now) {
        //currently active
        if (sharerequest.status != "active") {
            updateShareRequest(sharerequest.id, {status: "active"});
        }
        return "Active";
    } else if (sharerequest.start.seconds > now) {
        //future start
        if (sharerequest.status != "pending") {
            updateShareRequest(sharerequest.id, {status: "pending"});
        }
        return "Pending";
    } else {
        if (sharerequest.status != "draft") {
           updateShareRequest(sharerequest.id, {status: "draft"});
        }
        return "Draft";
    }



}



const getFormattedDateString = (dateobject) => {
    if (dateobject && dateobject.seconds != undefined) {
    const timestamp = new Timestamp(dateobject.seconds, dateobject.nanoseconds);

    return moment(new Date(timestamp.toDate())).format("MMM D, h:mma")
    } else {
        return moment(dateobject).format("MMM D, h:mma")
    }
}


const handleOpenResults = () => {
    setOpenResults(true);
  };

  const handleCloseResults = () => {
    setOpenResults(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      className={classes.bodyBackground}
    >
      <Container>
        {/** Start Page Header */}
        <Grid container={true} className={classes.buffer}>
          <Grid item={true} xs={12} md={6} className={classes.gridItem} >
            <Box component="header" className={classes.root} >

              <Typography variant={`h4`} color="secondary" className={classes.headline} gutterBottom={false} >
                {team && team.name !== "Default" ? team.name : 'RESEARCH'}
              </Typography>
            </Box>
          </Grid>
          <Grid item={true} xs={12} md={6} className={classes.actionBar} >
              <div>
              <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                  <Button variant="contained" color="secondary" size="medium" className={classes.button}>
                      <AppsIcon /> 
                  </Button>
                  <Button variant="contained" color="secondary" size="medium" className={classes.button}>
                      <ListIcon /> 
                  </Button>
                  <Button variant="contained" color="secondary" size="medium" className={classes.button} onClick={(event) => handleEdit(null)}>
                      <AddCircleOutlineIcon /> Create New Request
                  </Button>

                 

                  {/*
                  <Button variant="contained" color="secondary" disabled={disableDownload} size="medium" className={classes.button}>
                      <CloudDownloadIcon />
                  </Button>
                      */}


              </ButtonGroup>
              </div>
          </Grid>
        </Grid>
        {/** End Page Header */}

       

        {router.query.paid && auth.user.planIsActive && (
          <Box mx="auto" mb={4} maxWidth={400}>
            <Alert severity="success">
              You are now subscribed to the {auth.user.planId} plan
              <span
                role="img"
                aria-label="party"
                style={{ marginLeft: "10px" }}
              >
                🥳
              </span>
            </Alert>
          </Box>
        )}


           


{results && 
        <Grid container spacing={3} alignItems="stretch">


            {results.map((data, index) => 
        <Grid item xs={4} key={data.id} style={{display: 'flex'}}>
          
        <Card>
            <CardActionArea onClick={(event) => handleEdit(data)} >
            <CardHeader
                avatar={
                    data.type === "live" ? 
                    <Tooltip title="Live Event"><PeopleAltIcon color="secondary" fontSize="large"/></Tooltip>
                        :
                        <Tooltip title="Recorded Event"><VideocamIcon color="secondary" fontSize="large"/> </Tooltip>
                    
                }
                //action={
                //<IconButton aria-label="settings">
                //    <MoreVertIcon />
                //</IconButton>
                //}
                title={getStatus(data)}
                subheader={getFormattedDateString(data.start) + " - " + getFormattedDateString(data.end)} //"Sept 14, 2023 - Sept 15, 2023"
            />
                <CardMedia
                component="img"
                alt="Contemplative Reptile"
                height="140"
                image={data.image_url === undefined ? getRandomPlaceholderImage(data.id) : data.image_url}
                title="Contemplative Reptile"
                />
                <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    {data.title}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    {data.desc}
                </Typography>
                </CardContent>
            </CardActionArea>
            
        </Card>

        </Grid>
            )}
       

        </Grid>
}
         { /*<Box>
          <Card>
            <CardContent className={classes.cardContent}>
              <Box className={classes.forceScatterH}>
                <Typography variant="h5" paragraph={true}>
                  <strong>THING Team </strong><br></br>
                </Typography>
                
              </Box>
            </CardContent>
          </Card>
            </Box>*/}
         

         <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        classes={classes}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">{activeShareRequest !== null ? "Edit Share Request" : "Create Share Request"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Manage your share request.
          </DialogContentText>
          
            <ShareRequestForm shareRequest={activeShareRequest} team={team} callback={handleClose}/>


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

         
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        classes={classes}
        open={openResults}
        onClose={handleCloseResults}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title" type={activeShareRequest !== null ? activeShareRequest.type : "live"} onClose={handleCloseResults}>
             {activeShareRequest !== null ? activeShareRequest.title : "title"} ({activeShareRequest !== null ? getFormattedDateString(activeShareRequest.start) : ""} → {activeShareRequest !== null ? getFormattedDateString(activeShareRequest.end) : ""})
        </DialogTitle>

        <DialogContent>
          {/*<DialogContentText>
            {activeShareRequest !== null ? activeShareRequest.desc : "desc"}
        </DialogContentText>*/}
          
            
          <ShareRequestResults team={team} shareRequest={activeShareRequest !== null ? activeShareRequest : {}} />

        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={handleCloseResults} size="medium">
            Close
          </Button>
        </DialogActions>
      </Dialog>

        
      </Container>





    </Section>
  );
}

export default ResearchDashboardSection;
