import React from 'react'
import csvDownload from 'json-to-csv-export'
import Button from '@material-ui/core/Button';


const CustomCvsButton = (props) => {
  const { data, filename, delimiter, headers, ...others } = props

  return (
    <Button
      onClick={() => csvDownload({ data, filename, delimiter, headers })}
      {...others}
    >
      {props.children || 'Download Data'}
    </Button>
  )
}
export default CustomCvsButton