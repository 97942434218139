import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import AspectRatio from "./AspectRatio";


const useStyles = makeStyles((theme) => ({
  header: {
    paddingTop: 0,
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

function TestimonialsSectionImages(props) {
  const classes = useStyles();

  const items = [
    {
      image: "./images/testimonials/1.jpg",
      aspect: 1.777777,
      xs: 7,
    },
    {
      image: "./images/testimonials/2.jpg",
      aspect: 1.3333333,
      xs: 5,
    },
    {
      image: "./images/testimonials/4.jpg",
      aspect: 1.333333,
      xs: 5,
    },
    {
      image: "./images/testimonials/3.jpg",
      aspect: 1.777777,
      xs: 7,
    },
    
    
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Grid container={true} justifyContent="center" spacing={4}>
          {items.map((item, index) => (
            <Grid item={true} xs={item.xs} key={index}>

                <AspectRatio ratio={item.aspect}>
                  <img src={item.image} style={{width: "100%", height: "auto"}}/>
                </AspectRatio>

              
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
}

export default TestimonialsSectionImages;
