import React, { useState, useEffect } from "react";
import { InfluxDB } from "@influxdata/influxdb-client";
import { ResponsiveLine } from "@nivo/line";
import { Timestamp } from "firebase/firestore";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Link, useRouter } from "./../util/router";
import { useAuth } from "./../util/auth";
import ResultIcon from "./immersion/ResultIcon";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import HotelIcon from '@material-ui/icons/Hotel';
import RepeatIcon from '@material-ui/icons/Repeat';
import Paper from '@material-ui/core/Paper';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import { ShareRequestResultsTimeline } from "./ShareRequestResultsTimeline";
import { ShareRequestResultsSummary } from "./ShareRequestResultsSummary";
import { useShareVideoResultOnce, useShareVideoResultScores } from "../util/db";
import { redirectToShareResultCheckout, getStripeSessionDetails } from "./../util/stripe";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';




const moment = require('moment');

const token = process.env.REACT_APP_INFLUX_TOKEN;
const org = process.env.REACT_APP_INFLUX_ORG;
const bucket = process.env.REACT_APP_INFLUX_BUCKET;
const url = process.env.REACT_APP_INFLUX_URL;

const useStyles = makeStyles((theme) => ({
    headline: {
      fontWeight: "800",
      //color: "#ffffff",
    },
    doBlur: {
      filter: "blur(15px)",
    },
    noBlur: {
      //filter: "blur(15px)",
    },
    hrMargin: {
      margin: "30px",
    },
    paper: {
        padding: '6px 16px',
      },
      secondaryTail: {
        backgroundColor: theme.palette.secondary.main,
      },
  }));

export const ShareRequestVideoResults = (props) => {
    const classes = useStyles();
    const auth = useAuth();
    const router = useRouter();
    const { team, shareRequest, legend, isPaid, downloadCallback, ...otherProps } = props;


    const { data: shareVideoResult, status: svrStatus, error: svrError, } = useShareVideoResultOnce(shareRequest.id);
    const { data: scores, status: scoresStatus, error: scoresError, } = useShareVideoResultScores(shareRequest.id);


    const [data, setData] = useState([]);
    const [immersionDataPoints, setImmersionDataPoints] = useState([]);
    const [safetyDataPoints, setSafetyDataPoints] = useState([]);
    const [downloadData, setDownloadData] = useState([]);
    
    
    const [nobuyopen, setNobuyopen] = useState(false);
    const [startRange, setStartRange] = useState("");
    const [endRange, setEndRange] = useState("");
    const [duration, setDuration] = useState();

    const [immersionOverall, setImmersionOverall] = useState(0);
    const [safetyOverall, setSafetyOverall] = useState(0);
    
    const [stripeSession, setStripeSession] = useState();
    

   
    //TODO: replace the group id with an array of users
    const groupId = "tues";
    const aggWindow = "1m";
   
  // console.log(shareRequest);

  // console.log(shareVideoResult);
  // console.log(scores);
   

   

   

    useEffect(() => {
        if (shareRequest) {
            console.log(shareRequest);
            let sr = getMomentFromTimestamp(shareRequest.start).toISOString();
            setStartRange(sr);
            let er = getMomentFromTimestamp(shareRequest.end).toISOString();
            setEndRange(er);
            let du = moment.duration(getMomentFromTimestamp(shareRequest.start).diff(getMomentFromTimestamp(shareRequest.end)));
            setDuration(du);

            

            console.log(duration);

            //loadMinuteData(sr, er);
        }

        


      }, []);

      useEffect(() => {
        if (shareVideoResult) {
            //console.log("shareVideoResult");
            //console.log(shareVideoResult);


              setImmersionOverall(shareVideoResult.immersion);
              setSafetyOverall(shareVideoResult.safety);

            

        }
      }, [shareVideoResult]);


      useEffect(() => {
        if (scores) {
            //console.log("scores");
            //console.log(scores);


              //setImmersionOverall(shareVideoResult.immersion);
             // setSafetyOverall(shareVideoResult.safety);

              aggregateScores()
        }
      }, [scores]);

      
      
const buy = (quantity) => {

    if (quantity >= 1) {
    redirectToShareResultCheckout(shareRequest.id, shareRequest.owner, "pr_tuesday_sr_10", quantity).catch((error) => {
        console.log(error.message);
      });
    } else {
        setNobuyopen(true);
    }

}

const handleClose = () => {
    setNobuyopen(false);
  };

    const averageArray = (nums) => {
        return nums.reduce((a, b) => (a + b)) / nums.length;
    }


    const getMomentFromTimestamp = (timestampObject) => {
        const timestamp = new Timestamp(timestampObject.seconds, timestampObject.nanoseconds);
    
        return moment(new Date(timestamp.toDate()))
    }


    const aggregateScores = () => {
        var overallImmersion = []
        var overallSafety = []
        var downloadDataset = []
        let finalData = []

        var participants = scores.length;
        var totalSeconds = shareVideoResult.duration_seconds;

        if (participants >= 1) {

            

            for (let index = 0; index < totalSeconds; ++index) {
                var rtI = 0;
                var rtS = 0;
                scores.forEach(scoreItem => {
                    if (scoreItem.immersion_points.length >= index ) {
                        var sI = scoreItem.immersion_points[index]
                        rtI = rtI + sI
                    }

                    if (scoreItem.safety_points.length >= index ) {
                        var sS = scoreItem.safety_points[index]
                        rtS = rtS + sS
                    }

                });
                //{ "x": res[i]['_time'], "y" : Math.round(res[i]['e']) }
                overallImmersion.push({ "x": index, "y" : Math.round(rtI/participants) });
                overallSafety.push({ "x": index, "y" : Math.round(rtS/participants) });

                downloadDataset.push({ "timestamp": index, "immersion" : Math.round(rtI/participants), "safety" : Math.round(rtS/participants) });


                
            }

            //console.log(`OVERALL IMM: ${overallImmersion}`);
            //console.log(`OVERALL SAFE: ${overallSafety}`);
            
            setImmersionDataPoints(overallImmersion);
            setSafetyDataPoints(overallSafety);

            setDownloadData(downloadDataset);
            downloadCallback(downloadDataset);

            finalData.push({"id": "Immersion", "data" : overallImmersion});
            finalData.push({"id": "Safety", "data" : overallSafety});
    
            //console.log(finalData);
    
            setData(finalData);

        }



        //setImmersionDataPoints(engagementPoints);
        //setSafetyDataPoints(safetyPoints);
    }


    const loadMinuteData = (start, end) => {
        console.log("loadMinuteData");

        let localQuery = `from(bucket: "${bucket}")
            |> range(start: ${start}, stop: ${end})
            |> filter(fn: (r) => r["_measurement"] == "score")
            |> filter(fn: (r) => r["_field"] == "e" or r["_field"] == "s")
            |> filter(fn: (r) => r["gid"] == "${groupId}")
            |> group(columns: ["_measurement", "_field", "gid"])
            |> aggregateWindow(every: ${aggWindow}, fn: mean, createEmpty: true)
            |> pivot(rowKey: ["_time"], columnKey: ["_field"], valueColumn: "_value" )
            |> yield(name: "mean")`;

            console.log("localQuery = " + localQuery);

        let res = [];
        const influxQuery = async () => {
          //create InfluxDB client
          const queryApi = await new InfluxDB({ url, token }).getQueryApi(org);
          //console.log(queryApi);
    
          //make query
          await queryApi.queryRows(localQuery, {
            next(row, tableMeta) {
             //  console.log("a");
              const o = tableMeta.toObject(row);
             //push rows from query into an array object
              res.push(o);
            },
            complete() {
              
    
              let tmpDownloadDataset = [] 
    
              let finalData = []
              let engagement = []
              let safety = []

              let engagementPoints = []
              let safetyPoints = []


            //  console.log(res);
              //variable is used to track if the current ID already has a key
              var exists = false
    
              for(let i = 0; i < res.length; i++) {
                //let d = moment(new Date(res[i]['_time'])).format("YYYY-MM-DD HH:mm:ss")
                //let jd = new Date(res[i]['_time'])
                engagementPoints.push(res[i]['e']);
                safetyPoints.push(res[i]['s']);

                engagement.push({ "x": res[i]['_time'], "y" : Math.round(res[i]['e']) });
                safety.push({ "x" : res[i]['_time'], "y" : Math.round(res[i]['s']) });
    
                tmpDownloadDataset.push({"date": res[i]['_time'], "immersion": Math.round(res[i]['e']), "safety": Math.round(res[i]['s'])});
    
                
              }
    
              finalData.push({"id": "Immersion", "data" : engagement});
              finalData.push({"id": "Safety", "data" : safety});
    
              console.log(finalData);
    
              setData(finalData);
              setImmersionDataPoints(engagementPoints);
              setSafetyDataPoints(safetyPoints);

              setImmersionOverall(averageArray(engagementPoints));
              setSafetyOverall(averageArray(safetyPoints));

              //console.log(engagementPoints);
              //console.log(safetyPoints);
              setDownloadData(tmpDownloadDataset);
              downloadCallback(tmpDownloadDataset);
          
            },
            error(error) {
              console.log("query failed- ", error);
            }
          });
         
        };
    
        influxQuery();
    
      }



    return (
        <>


            {immersionOverall && immersionOverall > 0 &&
            <>
            
            
              <Grid container spacing={5} alignItems="stretch">
              <Grid item xs={12} md={1}></Grid>
                    <Grid item xs={12} md={3}>
                    <ResultIcon maxheight={'200px'} maxwidth={'200px'} fontsize={'100px'} scoretopbuffer={'20px'} experiencedata={!!immersionDataPoints ? immersionDataPoints : []} immersionScore={immersionOverall} safetyScore={safetyOverall} />

                    </Grid>
                    <Grid item xs={12} md={7}>
                    <Typography variant={`h4`} color="primary" className={classes.headline} gutterBottom={false} >
                        Result Summary
                    </Typography>

                {immersionOverall && safetyOverall &&
                    <ShareRequestResultsSummary immersion={immersionOverall} safety={safetyOverall}  attendeeCount={shareVideoResult.completed_count}/>
                }
                    </Grid>
                    <Grid item xs={12} md={1}></Grid>
                </Grid>
               

                <Grid container spacing={5} alignItems="stretch">
                <Grid item xs={12} md={1}></Grid>
              <Grid item xs={12} md={3} style={{display: 'grid', alignItems: 'center', justifyItems: 'center'}}>
                    <Typography variant={`h1`} color="secondary" className={classes.headline} gutterBottom={false} >
                        {shareVideoResult.completed_count}
                    </Typography>

                    <Typography variant={`h6`} color="primary" gutterBottom={false} >
                    Completed
                    </Typography>
              </Grid>
              <Grid item xs={12} md={3} style={{display: 'grid', alignItems: 'center', justifyItems: 'center'}}>
              <Typography variant={`h1`} color="secondary" className={classes.headline} gutterBottom={false} >
                        {shareRequest.accept_count}
                    </Typography>

                    <Typography variant={`h6`} color="primary" gutterBottom={false} >
                    Accepted
                    </Typography>
              </Grid>
              <Grid item xs={12} md={3} style={{display: 'grid', alignItems: 'center', justifyItems: 'center'}}>
              <Typography variant={`h1`} color="secondary" className={classes.headline} gutterBottom={false} >
                        {shareRequest.deny_count}
                    </Typography>

                    <Typography variant={`h6`} color="primary" gutterBottom={false} >
                    Denied
                    </Typography>
              </Grid>


              <Grid item xs={12} md={1} ></Grid>
              </Grid>


                { isPaid === false && (
                  <>
                 
                  <hr className={classes.hrMargin}></hr>
              <Grid container spacing={5} alignItems="stretch">
              <Grid item xs={1} ></Grid>
              <Grid item xs={10} justifyContent="center">
              <Typography variant={`h3`} color="primary" gutterBottom={false} style={{textAlign: 'center'}} paragraph={true}>
                  Want the full report?
                  </Typography>

                  <Typography variant={`p`} color="primary" gutterBottom={false} paragraph={true} style={{textAlign: 'center'}} >
                  With the full report, you can see a timeline graph by minute of the complete experience, download the data for deeper analysis and break the timeline into 5, 15, 30, or 60 minute segments to get a deep understanding of how your audience responded to this experience.
                  </Typography>

                  <Typography variant={`p`} color="primary" gutterBottom={false}  paragraph={true} style={{textAlign: 'center'}} >
                  Full report is only $10 per completed recipient!
                  </Typography>

                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <Button variant="contained" color="secondary" size="large" className={classes.button} onClick={(event) => buy(shareVideoResult.completed_count)}  >
                      Buy Now
                  </Button>
                  </div>

                </Grid> 

                <Grid item xs={1} ></Grid>
              </Grid>
              <hr className={classes.hrMargin}></hr>
              </>
              )}


                <Grid container spacing={5} alignItems="stretch">
              <Grid item xs={1} ></Grid>
              <Grid item xs={10} style={{height: '600px'}} className={isPaid === true ? classes.noBlur : classes.doBlur}>

                    

                <ResponsiveLine 
  data={data}
  margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
  curve="natural"
  enableGridX={false}
  enableArea={true}
  axisBottom={{
    "orient": "bottom",
    "tickSize": 5,
    "tickPadding": 5,
    "tickRotation": 90,
    "format": (d) => {return d % 10 === 0 ? d : '';},
    "legend": legend,
    "legendOffset": 10,
    "legendPosition": 'middle'
  }}
  
  yScale={{
    type: 'linear',
    min: 0,
    max: 100,
    stacked: false,
    reverse: false
}}
  lineWidth={5}
  pointSize={0}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
  legends={[
    {
        anchor: 'bottom-right',

        direction: 'column',
        justify: false,
        translateX: 100,
        translateY: 0,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 80,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: 'circle',
        symbolBorderColor: 'rgba(0, 0, 0, .5)',
        effects: [
            {
                on: 'hover',
                style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1
                }
            }
        ]
    }
    ]}
/>


                </Grid>
                <Grid item xs={1} ></Grid>
                </Grid>


                {
                /*
                <ShareRequestResultsTimeline shareRequest={shareRequest} team={team} startRange={startRange} endRange={endRange} isPaid={isPaid}/>
                */}

                </>

            }


    <Dialog
        open={nobuyopen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"No completed participants"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            There were no completed participants for this experience.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>



        </>
    )


};

