import React, { useState, useEffect } from "react";
import { InfluxDB } from "@influxdata/influxdb-client";
import { ResponsivePie } from "@nivo/pie";


//const moment = require('moment');

//console.log(process.env.REACT_APP_INFLUX_URL);

const token = process.env.REACT_APP_INFLUX_TOKEN;
const org = process.env.REACT_APP_INFLUX_ORG;
const bucket = process.env.REACT_APP_INFLUX_BUCKET;
const url = process.env.REACT_APP_INFLUX_URL;




export const PieChart = (props) => {
  const [piedata, setPieData] = useState([]);
  const { startRange, aggWindow, groupId, legend, downloadCallback, ...otherProps } = props;

  let query = `data = from(bucket: "${bucket}")
  |> range(start: ${startRange})
  |> filter(fn: (r) => r["_measurement"] == "score")
  |> filter(fn: (r) => r["_field"] == "e")
  |> filter(fn: (r) => r["gid"] == "${groupId}")
  |> aggregateWindow(every: ${aggWindow}, fn: mean, createEmpty: false)

cap = data
   |> filter(fn: (r) => r["_value"] >= 90)
   |> count()
   |> set(key: "_field", value: "Captivated")

absorb = data
   |> filter(fn: (r) => r["_value"] >= 71 and r["_value"] <= 89)
   |> count()
   |> set(key: "_field", value: "Absorbed")  

inter = data
   |> filter(fn: (r) => r["_value"] >= 31 and r["_value"] <= 70)
   |> count()
   |> set(key: "_field", value: "Interested")   

indifferent = data
   |> filter(fn: (r) => r["_value"] >= 11 and r["_value"] <= 30)
   |> count()
   |> set(key: "_field", value: "Indifferent")  

tunedout = data
   |> filter(fn: (r) => r["_value"] <= 10)
   |> count()
   |> set(key: "_field", value: "Tuned Out")   


union(tables: [cap, absorb, inter, indifferent, tunedout])
  |> pivot(rowKey: ["gid"], columnKey: ["_measurement"], valueColumn: "_value" )`;

  
  //console.log(query);
  //setData(fakeData);

  useEffect(() => {
    const interval = setInterval(() => {
      loadPageData();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    loadPageData();
    
  }, [startRange]);

  const loadPageData = () => {
    console.log("loadPiePageData");


    let pieres = [];
    const influxQuery = async () => {
      //create InfluxDB client
      const queryApi = await new InfluxDB({ url, token }).getQueryApi(org);
      //console.log(queryApi);

      //make query
      await queryApi.queryRows(query, {
        next(row, tableMeta) {
           //console.log(row);
          const o = tableMeta.toObject(row);
         //push rows from query into an array object
         pieres.push(o);
        },
        complete() {
          
          let finalPieData = []
        //  console.log(pieres);

            let dataMap = new Map([
                ["Captivated", 0],
                ["Absorbed", 0],
                ["Interested", 0],
                ["Indifferent", 0],
                ["Tuned Out", 0],
            ]);

        if (pieres.length >= 1) {
          for(let i = 0; i < pieres.length; i++) {
            let x = dataMap.get(pieres[i]["_field"]);
            dataMap.set(pieres[i]["_field"], x + pieres[i]["score"])

          }

          dataMap.forEach( function(value, key) {
       //     console.log(key + " == " + value);
            if (value > 0) {
                let pieItem = {
                    "label": key,
                    "id": key,
                    "value": value,
                }
        
                finalPieData.push(pieItem);
            }
            });

          


          //console.log(finalData);
          setPieData(finalPieData);
          downloadCallback(finalPieData, startRange);

        } else {
            setPieData([]);
        }

      
        },
        error(error) {
          console.log("query failed- ", error);
        }
      });
     
    };

    influxQuery();

  }



  return (

    <ResponsivePie
    data={piedata.length >= 1 ? piedata : []}
    margin={{ top: 40, right: 80, bottom: 120, left: 80 }}
    innerRadius={0.5}
    padAngle={0.7}
    cornerRadius={3}
    activeOuterRadiusOffset={8}
    borderWidth={1}
    borderColor={{
        from: 'color',
        modifiers: [
            [
                'darker',
                0.2
            ]
        ]
    }}
    arcLinkLabelsSkipAngle={10}
    arcLinkLabelsTextColor="#333333"
    arcLinkLabelsThickness={2}
    arcLinkLabelsColor={{ from: 'color' }}
    arcLabelsSkipAngle={10}
    arcLabelsTextColor={{
        from: 'color',
        modifiers: [
            [
                'darker',
                2
            ]
        ]
    }}
    //colors={{ datum: 'data.color' }}
    defs={[
        {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            size: 4,
            padding: 1,
            stagger: true
        },
        {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            rotation: -45,
            lineWidth: 6,
            spacing: 10
        }
    ]}
    fill={[
        {
            match: {
                id: 'xxCaptivated'
            },
            id: 'dots'
        },
        {
            match: {
                id: 'xxAbsorbed'
            },
            id: 'dots'
        },
        {
            match: {
                id: 'go'
            },
            id: 'dots'
        },
        {
            match: {
                id: 'python'
            },
            id: 'dots'
        },
        {
            match: {
                id: 'scala'
            },
            id: 'lines'
        },
        {
            match: {
                id: 'lisp'
            },
            id: 'lines'
        },
        {
            match: {
                id: 'elixir'
            },
            id: 'lines'
        },
        {
            match: {
                id: 'javascript'
            },
            id: 'lines'
        }
    ]}
    legends={[
        {
            anchor: 'bottom',
            direction: 'row',
            justify: false,
            translateX: 0,
            translateY: 56,
            itemsSpacing: 0,
            itemWidth: 100,
            itemHeight: 18,
            itemTextColor: '#999',
            itemDirection: 'left-to-right',
            itemOpacity: 1,
            symbolSize: 18,
            symbolShape: 'circle',
            effects: [
                {
                    on: 'hover',
                    style: {
                        itemTextColor: '#000'
                    }
                }
            ]
        }
    ]}
/>

  )
};