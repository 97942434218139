import React from "react";
import LongContent from "./LongContent";

function LegalTerms(props) {
  return (
    <LongContent>
      <h1>Terms of Service</h1>
      <p>
      Please read these terms of service carefully before using the services offered by Immersion Neuroscience, Inc. (“Immersion”, “Tuesday”, “us”, “we”). These terms (the “Agreement”) set forth 
      the terms and conditions of our relationship and govern your use of the BestTuesdayEver.com, Enterprise.BestTuesdayEver.com and any other subdomain related websites (collectively, the “Site”) 
      and the services, features, content, and wearable/watch mobile device applications/apps offered by Tuesday (collectively, the “Services”).  By accessing the Site or using 
      the Services, you agree to be bound by the terms of this Agreement, which also includes Tuesday‘s <a href="/legal/privacy-policy">Privacy Policy</a>.
      </p>


    <h2>The Tuesday Site and Services</h2>
    <p>
    <ol>
    <li>The Site and the Services provide general education and information designed to assist users with improving their emotional wellbeing & psychological safety (collectively, “Emotional Fitness”), using its proprietary algorithmic and analytics technology to measure certain key body metric information to provide the user with useful information regarding Emotional Fitness.</li>
    <li>You understand that Tuesday is primarily a technology and software provider and does not render nor provide medical care or other professional medical services through the Site and/or Services.</li>
    <li>You understand that the Site and/or Services do not and cannot themselves provide any medical advice. As a result, Tuesday advises you to always seek the advice of a physician or other qualified health care provider with any questions regarding your personal health or medical issues. Never disregard, avoid, or delay in obtaining medical advice from your doctor or other qualified health care provider because of something you have read on the Site or any information you receive by and through the Services. If you have or suspect that you have a medical problem or condition, please contact a qualified health care professional immediately.</li>
    <li>Tuesday is not a crisis center nor does it provide crisis services. If you are thinking about suicide or if you are considering taking actions that may cause harm to you or others or if you feel that you or any other person may be in any danger call your local crisis center, dial 911, or go to the nearest emergency room. If you are having suicidal thoughts and live in the US you can also call the National Suicide Prevention Lifeline at 1-800-273-8255.</li>
    <li>The information contained on or provided through the Site or the Services has been prepared by us without reference to any particular user’s medical, mental, or personal health situation, and does not constitute tailored or professional medical advice.</li>
    </ol>

    </p>


    <h2>Fees and Payment</h2>
    <p>
    <ol>
    <li>Service Fees. While Tuesday makes a large amount of information available without cost, it may in the future charge fees for all or some of its services, including but not limited to any software applications or apps on any device or platform (“Service Fees”). </li>
    <li>Payment of Service Fees. When you purchase services for which Service Fees apply, you may be required to provide Tuesday with valid, up-to-date credit card or other payment information. </li>
    <li>You agree that your purchases are not contingent on the delivery of any future functionality or features, or dependent on any oral or written public comments made by Tuesday regarding future functionality or features.</li>

    </ol>

    </p>

    <h2>The Agreement between Tuesday and You</h2>
    <p>
      <strong>You are responsible for reviewing changes to this Agreement</strong>

      <ol>
        <li>This Agreement supersedes all prior or contemporaneous agreements, representations, warranties and understandings with respect to the Site and the Services, the content, products or services provided by or through the Site and the Services, and the subject matter of this Agreement.</li>
        <li>This Agreement applies to all users of the Site and the Services.</li>
        <li>Tuesday may make changes to this Agreement from time to time without specifically notifying you.</li>
        <li>Tuesday will post the latest Agreement on the Site, but it is up to you to review it before using the Site or Services.</li>
        <li>If you continue to use the Site or Services after any of these changes, your continued use will mean that you have accepted any changes to the Agreement.</li>
        <li>Some services offered through the Site and the Services may be subject to additional terms and conditions specified by Tuesday from time to time and your use of such services is subject to those additional terms and conditions, which are incorporated into this Agreement by this reference.</li>
        <li>In addition, the Services may or will be accessible via a mobile phone, Apple or Android watch, tablet, or other wireless device (collectively, “Mobile Services”). Your mobile carrier’s normal messaging, data, and other rates and fees will apply to your use of the Mobile Services. In addition, downloading, installing, or using certain Mobile Services may be prohibited or restricted by your mobile carrier, and not all Mobile Services may work with all carriers or devices. Therefore, you are solely responsible for checking with your mobile carrier to determine if the Mobile Services are available for your mobile device(s), what restrictions, if any, may be applicable to your use of the Mobile Services, and how much they will cost you. </li>
      </ol>

    </p>

    <h4>Tuesday may edit or modify anything on the Site or Services without notice</h4>
    <p>Tuesday is committed to delivering a positive user experience and you understand that Tuesday reserves the right (but without undertaking any duty) to edit, monitor, review, delete, modify, or move any content or material provided or placed on or though the Site and the Services in its sole discretion, without notice and without undertaking any duty to do so.</p>

    <h4>You are responsible for accepting updated versions of the Site and Services</h4>
    <p>
    <ol>
      <li>If Tuesday provides updated versions of the Site and the Services and you do not accept these updated versions, you may not enjoy the most recent content, feature sets, and materials.</li>
      <li>If you do not accept updated versions of the Site and Services, Tuesday shall not bear any responsibility or liability for your decision.</li>
    </ol>

    </p>

    <h4>Consent to Electronic Communications</h4>
    <p>You consent to receive communications from Tuesday electronically, and you agree that all agreements, notices, disclosures and other communications that Tuesday provides to you electronically, via email, or on the Services, satisfy any legal requirement that such communications or agreements be in writing.</p>

    <h4>What Laws and Rules You Are Responsible For Following</h4>
    <p>
    <ol>
      <li>You promise not to use the Site or the Services for any purpose that is prohibited by this Agreement.</li>
      <li>You are responsible for all of your activity in connection with the Site and the Services.</li>
      <li>You shall abide by all applicable local, state, national and international laws and regulations and, if you represent a business, any advertising, marketing, privacy, or other self-regulatory code(s) applicable to you. </li>
      <li>Tuesday may provide you a username and password. You understand that you are entirely responsible for maintaining the confidentiality of your password and user name and any other security information related to your account.</li>
      <li>You understand that you will be fully responsible for all activities that occur under your account, user name and/or password.</li>
      <li>You agree not to allow someone who is not you to use your account, username or password at any time.</li>
      <li>You agree to immediately notify Company of any unauthorized use of your password or your user name.</li>

    </ol>

    </p>

    <h4>It is your responsibility to make sure this Agreement and your use of the Site and Services complies with all laws applicable to you</h4>
    <p>
    <ol>
      <li>You understand Tuesday may, in its sole discretion, refuse to offer the Site or the Services to any person or entity and change its eligibility criteria at any time and that Tuesday reserves the right (without undertaking any duty) to use “geo-filtering” or other technologies to block access to certain jurisdictions, in its sole discretion, without notice.</li>
      <li>You are solely responsible for ensuring that this Agreement complies with all laws, rules and regulations applicable to you.</li>
      <li>You understand that your right to access the Site or Services will be revoked where this Agreement or use of the Site or the Services is prohibited and, if that is the case, you agree not to use or access the Site or the Services in any way.</li>
    </ol>

    </p>

    <h4>Tuesday may terminate your use of the Site and/or Services without reason or notice to you</h4>
    <p>While Tuesday respects its users, you agree that Tuesday may terminate your membership or use of the Site and/or the Services and prohibit you from accessing the Site and/or the Services, for any reason, or no reason, and with or without notice.</p>
    
    <h4>You agree to comply with Tuesday’s conduct policies when using the Site or Services</h4>
    <p>We do our best to keep the Site and the Services safe and operational, but we cannot guarantee it. We need your help to do that, which includes the following commitments:

      <ol>
        <li>You will not modify, adapt, appropriate, reproduce, distribute, translate, create derivative works or adaptations of, publicly display, republish, repurpose, sell, trade, or in any way exploit the Services or Site except as expressly authorized by Tuesday;</li>
        <li>You will not take any action that imposes or may impose (as determined by Tuesday in its sole discretion) an unreasonable or disproportionately large load on Tuesday’s (or its third party providers’) infrastructure;</li>
        <li>You will not decipher, decompile, disassemble, reverse engineer or otherwise attempt to derive any source code or underlying ideas or algorithms of any part of the Services, except to the limited extent applicable laws specifically prohibit such restriction;</li>
        <li>You will not interfere or attempt to interfere with the proper working of the Site and Services or any activities conducted on the Site and Services;</li>
        <li>You will not bypass any measures Tuesday may use to prevent or restrict access to the Site and Services (or other accounts, computer systems or networks connected to the Site and Services);</li>
        <li>You will not run any form of auto-responder or “spam” on the Site and Services;</li>
        <li>You will not use manual or automated software, devices, or other processes to “crawl” or “spider” any page of the Site;</li>
        <li>You will not harvest or scrape any content or materials from the Site and Services;</li>
        <li>You will not copy, rent, lease, distribute, or otherwise transfer any of the rights that you receive hereunder;</li>
        <li>You will not threaten, intimidate or harass another user;</li>
        <li>You will not solicit passwords or personally identifying information (this includes, but is not limited to, someone else’s name, birthdate, home address, IP address, social security number, or credit card number) for commercial or unlawful purposes;</li>
        <li>You will not upload, post, transmit, share, store or otherwise make available any material that contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment;</li>
        <li>You will not knowingly transmit false or misleading information through the Site or the Services.</li>
        <li>You will not use the Site and Services in any manner or transmit any content and/or materials that: infringes (or results in the infringement of) Tuesday’s or any third party’s intellectual property or other rights; is (or you reasonably believe to be) illegal, fraudulent, or unauthorized, or in furtherance of any illegal, counterfeiting, fraudulent, pirating, unauthorized, or violent activity, or that involves (or you reasonably believe to involve) any stolen, illegal, counterfeit, fraudulent, pirated, or unauthorized material; does not comply with all applicable laws, rules, or regulations, including obtaining all necessary permits, licenses, registrations, etc. In the case of any proposed or actual transaction, “applicable” refers to both your own location and to location(s) of all other parties to the transaction; or would cause Tuesday to be in violation of any law or regulation, or to infringe any right of any third party.</li>
        <li>You will not publish falsehoods or misrepresentations that may damage Tuesday or any third party.</li>
        <li>You will not transmit any content and/or materials and will not use the Site and Services to post, store, transmit, offer, or solicit anything that contains the following, or that you know contains links to the following or to locations that in turn contain links to the following: material that we determine to be offensive (including material promoting or glorifying hate, violence, bigotry, or any entity (past or present) principally dedicated to such causes, or items associated with such an entity); material that is racially or ethnically insensitive, material that is defamatory, harassing or threatening; pornography (including any obscene material, and anything depicting children in sexually suggestive situations whether or not depicting actual children) or may be harmful to a minor; any virus, worm, Trojan horse, or other harmful or disruptive component; or anything that encourages conduct that would be considered a criminal offense, give rise to civil liability, violate any law or regulation or is otherwise inappropriate.</li>
        <li>You will not use the Site and Services to post, store, or transmit advertising, promotional material, or solicitations of goods or services, except in areas of the Site designated for the purpose.</li>
        <li>You will not otherwise take any action in violation of Tuesday’s guidelines and policies.</li>

      </ol>

    </p>

    <h4>Tuesday is not responsible for what happens outside of the Site and Services, including on websites the Site or Services link to or advertise</h4>
    <p>
      <ol>
        <li>Tuesday or its business partners may present advertisements or promotional materials via the Services.</li>
        <li>Your dealings with, or participation in promotions of any third-party advertisers via the Services are solely between you and such third party and your participation is subject to the terms and conditions associated with that advertisement or promotion.</li>
        <li>You agree that Tuesday is not responsible or liable for any loss or damage of any sort incurred as the result of any such dealings or as the result of the presence of such third parties via the Services.</li>
        <li>You understand the Site and the Services may contain (or you may receive from Tuesday, third parties or users) links to other web sites (“Third Party Sites”) or content posted, owned or originating from third parties such as, by way of example only, pictures, designs, photographs, graphics, text, sound, video, information, software, applications and any other content (“Third Party Content”).</li>
        <li>You understand and agree that Tuesday is not responsible for, and does not control, Third Party Sites and Third Party Content. You also understand and agree that Tuesday is not responsible for the availability of such external sites or resources, and does not endorse and is not responsible or liable for any content, advertising, products or other materials on or available from such Third Party Sites and Third Party Content.</li>
        <li>You acknowledge and agree that Tuesday shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of, or reliance upon, any such content, goods or services available on or through any such Third Party Site or Third Party Content.</li>
      </ol>


    </p>


    <h2>Who Owns What and How You Can Use It</h2>

    <h4>The copyright to all content on the Site and Services is owned by the provider of that content</h4>
    <p>
      <ol>
        <li>The content, organization, graphics, design, compilation, magnetic translation, digital conversion and other matters related to the Site and the Services (“Copyright Content”) are the property of Tuesday or may be, to a certain extent, the property of its content suppliers, and are protected under applicable copyright law, trademark law and other proprietary rights.</li>
        <li>You may not copy, redistribute, use or publish any part of the Site or the Services, except as allowed by this Agreement.</li>
        <li>You do not acquire ownership rights to any content, document or other materials viewed through the Site or the Services.</li>
      </ol>

    </p>


    <h4>You may not use trademarks appearing on the Site or Services in an infringing manner</h4>
    <p>
      <ol>
        <li>You agree that Tuesday, Immersion, Immersion Neuroscience, GetImmersion.com, BestTuesdayEver.com, 'Make love, not meh', and other Tuesday graphics, logos, page headers, button icons, scripts, and service names are trademarks, registered trademarks or trade dress of Tuesday or its affiliates (“Trademark Content”).</li>
        <li>Tuesday trademarks and trade dress may not be used in connection with any product or service that is not Tuesday’s, in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits Tuesday.</li>
        <li>All other trademarks not owned by Tuesday or its affiliates that may appear on this Site or the Services are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by Tuesday or its affiliates.</li>

      </ol>

    </p>

    <h4>You may use the Tuesday Site and Services for limited, noncommercial purposes</h4>
    <p>
      <ol>
        <li>Tuesday grants you a limited license to access and make personal use of the Site and the Services.</li>
        <li>Tuesday does not grant you the right to download (other than page caching) or modify the Site and the Services, or any portion of the Site and the Services.</li>
        <li>You understand Tuesday does not grant you the right to resell or make commercial use of the Site and the Services or their contents; make any derivative use of the Site and the Services or their contents; any downloading or copying of account information for the benefit of a third party, or merchant; or any use of data mining, robots, or similar data gathering and extraction tools.</li>
        <li>You understand the Site and the Services or any portion of Site and the Services may not be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial purpose.</li>
        <li>You may not frame or utilize framing techniques to enclose any trademark, logo, or other proprietary information of Tuesday and our affiliates without express written consent.</li>
        <li>You may not use any meta tags or any other “hidden text” utilizing Tuesday’s name or trademarks without the express written consent of Tuesday. Any unauthorized use terminates the permission or license granted by Tuesday.</li>
        <li>You may not engage in the copying, reproduction, publication, rearrangement, redistribution, modification, revision, alteration, or reverse engineering, of the Services.</li>
        <li>You are granted a limited, revocable, and nonexclusive right to create a hyperlink, or at the Company’s sole discretion other items such as widgets, buttons, or other web page elements, to the home page of Tuesday so long as the link does not portray Tuesday, its affiliates, or their products or services in a false, misleading, derogatory, or otherwise offensive matter.</li>
        <li>You may not use any Tuesday logo or other proprietary graphic or trademark as part of the link without express written permission.</li>
      </ol>

    </p>


    <h2>Legal Conditions</h2>
    <h4>Tuesday’s liability is limited</h4>
    <p>
      <ol>
        <li>Tuesday is not responsible for any Third Party Sites, Third Party Content, or any other content posted on the Site and the Services, whether caused by users of the Site and the Services, Tuesday, third parties or by any of the equipment or programming associated with or utilized in the Site and the Services.</li>
        <li>Tuesday is not responsible for the conduct, whether online or offline, of any user of the Site or the Services.</li>
        <li>Tuesday assumes no responsibility for any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, theft or destruction or unauthorized access to, or alteration of user communications.</li>
        <li>Tuesday is not responsible for any problems or technical malfunction of any telephone network or lines, cellular data provider or network, computer online systems, servers or providers, computer equipment, software, failure of email or players on account of technical problems or traffic congestion on the Services or Site, including injury or damage to users or to any other person’s computer, and/or mobile device.</li>
        <li>Neither Tuesday nor any of its affiliates, advertisers, promoters or distribution partners shall be responsible for any loss or damage, including personal injury or death, resulting from anyone’s use of the Site or the Services, any content posted on the Site or the Services or transmitted to users, or any interactions between users of the Site or the Services, whether online or offline.</li>

      </ol>

    </p>

    <h4>Disclaimer—For Educational Purposes Only, Not Substitute for Qualified Professional or Medical Advice</h4>
    <p>
    UNLESS PROVIDED BY A QUALIFIED COUNSELOR DIRECTLY TO YOU, THE INFORMATION PROVIDED THROUGH THE SITE AND THE SERVICES IS PROVIDED FOR EDUCATIONAL AND GENERAL INFORMATIONAL PURPOSES ONLY AND SHOULD NOT BE CONSIDERED MEDICAL ADVICE OR A SUBSTITUTE FOR INDIVIDUAL MEDICAL ADVICE, DIAGNOSIS, OR TREATMENT. TUESDAY IS NOT A MEDICAL SERVICES FIRM OR MEDICAL PRACTICE, AND IS NOT REGISTERED AS A MEDICAL PRACTICE OR MEDICAL SERVICES FIRM. COUNSELORS ARE NOT THE EMPLOYEES OR AGENTS OF TUESDAY.  
IT IS STRONGLY RECOMMEND TO ALWAYS SEEK THE ADVICE OF A QUALIFIED AND LICENSED PHYSICIAN OR OTHER QUALIFIED HEALTH CARE PROVIDER IN CONNECTION WITH ANY AND ALL QUESTIONS YOU MAY HAVE REGARDING A MEDICAL CONDITION, INCLUDING INFORMATION REGARDING WHICH DRUGS OR TREATMENT MAY BE APPROPRIATE FOR YOU. 

    </p>

    <h4>General Disclaimer and Limitation of Liability</h4>
    <p>
    WE TRY TO KEEP THE SITE AND SERVICES UP, BUG-FREE AND SAFE, BUT YOU USE THEM AT YOUR OWN RISK. THE INFORMATION FROM OR THROUGH THE SITE AND THE SERVICES IS PROVIDED “AS IS,” “AS AVAILABLE,” AND ALL WARRANTIES, EXPRESS OR IMPLIED, ARE DISCLAIMED. THE INFORMATION, SITE AND THE SERVICES MAY CONTAIN VIRUSES, BUGS, ERRORS, PROBLEMS OR OTHER LIMITATIONS. IN NO EVENT WILL TUESDAY OR ITS DIRECTORS, MANAGERS, MEMBERS, EMPLOYEES OR AGENTS HAVE ANY LIABILITY WHATSOEVER FOR YOUR USE OF ANY INFORMATION OR SERVICE, OR THE SITE. WE ARE NOT LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES (INCLUDING DAMAGES FOR LOSS OF BUSINESS, LOSS OF PROFITS, LITIGATION, OR THE LIKE), WHETHER BASED ON BREACH OF CONTRACT, BREACH OF WARRANTY, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR OTHERWISE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NO ADVICE OR INFORMATION OBTAINED BY YOU FROM US THROUGH THE SITE/SERVICE SHALL CREATE ANY WARRANTY, REPRESENTATION OR GUARANTEE NOT EXPRESSLY STATED IN THIS AGREEMENT.
    </p>

    <p>
    TUESDAY’S LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER, AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US FOR THE SERVICE AND USE OF THE SITE, BUT IN NO CASE WILL OUR LIABILITY TO YOU EXCEED $50.00 USD. YOU ACKNOWLEDGE THAT IF NO FEES ARE PAID TO US FOR THE SITE AND THE SERVICES, YOU SHALL BE LIMITED TO INJUNCTIVE RELIEF ONLY, UNLESS OTHERWISE PERMITTED BY LAW, AND SHALL NOT BE ENTITLED TO DAMAGES OF ANY KIND FROM US, REGARDLESS OF THE CAUSE OF ACTION.
    </p>
    <p>
IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA CIVIL CODE SECTION 1542, WHICH STATES, IN PART: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR.”

    </p>


    <h4>You agree to indemnify Tuesday</h4>
    <p>You agree to indemnify, defend, and hold harmless Tuesday, its contractors, licensors, subsidiaries and affiliates and their respective partners, directors, managers, members, officers, employees and agents from and against any and all claims and expenses, including any and all losses, costs, liabilities, and attorneys’ fees, arising out of or in connection with: (1) your use of the Site and Services, (2) any user Content, Third Party Content, Third Party Sites and any other content, (3) your violation of this Agreement, or of any law or the rights of any third party, and (4) your breach of this Agreement and/or any breach of your representations and warranties set forth herein.</p>

    <h4>You agree that law of the State of California applies to this Agreement</h4>
    <p>Subject to the arbitration clauses included below, if there is any dispute arising out of the Site and the Services, by using the Site, you expressly agree that any such dispute shall be governed by the laws of the State of California, without regard to its conflict of law provisions. Further, you and Tuesday agree to the jurisdiction of the state and federal courts located in State of California to resolve any dispute, claim, or controversy that relates to or arises in connection with the Agreement (and any non-contractual disputes/claims relating to or arising in connection with them) and is not subject to mandatory arbitration below.</p>

    <h4>Your general representation and warranty</h4>
    <p>
    <ol>
        <li>You represent and warrant that you will use the Site and Services in accordance with the Privacy Policy, with this Agreement and with all applicable laws and regulations (including without limitation any local laws or regulations in your country, state, city, or other governmental area, regarding online conduct and acceptable content, and including all applicable laws regarding the transmission of technical data exported from the United States or the country in which you reside).</li>
        <li>You represent and warrant that you will use the Site and the Services so not to infringe or misappropriate the intellectual property rights of any third party.</li>
      </ol>
    </p>

    <h2>Other Terms</h2>
    <p>
    <ol>
        <li>You and Tuesday agree that any cause of action arising out of or related to the Site or the Services must commence within one (1) year after the cause of action arose; otherwise, such cause of action is permanently barred.</li>
        <li>You agree that, except for claims for injunctive or equitable relief or claims regarding intellectual property rights (which may be brought in any competent court without the posting of a bond), any dispute arising under this Agreement shall be finally settled in pursuant to compulsory arbitration in the State of California, in accordance with the rules of the American Arbitration Association by arbitrators appointed in accordance with such rules.</li>
        <li>You agree any arbitration shall take place in the State of California in the English language and the arbitral decision may be enforced in any court. The prevailing party in any action or proceeding to enforce this Agreement shall be entitled to costs and attorneys’ fees. THERE IS NO JUDGE OR JURY IN ARBITRATION, AND COURT REVIEW OF AN ARBITRATION AWARD IS LIMITED. There may be more limited discovery than in court. The arbitrator must follow this agreement and can award the same damages and relief as a court (including attorney fees), except that the arbitrator may not award any relief, including declaratory or injunctive relief, benefiting anyone but the parties to the arbitration. This arbitration provision will survive termination of the Agreement.</li>
        <li>If any part of this Agreement is held invalid or unenforceable, that part will be construed to reflect the parties’ original intent, and the remaining portions will remain in full force and effect.</li>
        <li>A waiver by either party of any term or condition of this Agreement or any breach thereof, in any one instance, will not waive such term or condition or any subsequent breach thereof.</li>
        <li>Tuesday may assign its rights under this Agreement without condition.</li>
        <li>This Agreement will be binding upon and will inure to the benefit of the parties, their successors and permitted assigns. The headings of the paragraphs of this Agreement are inserted for convenience only and shall not be deemed to constitute part of this Agreement or to affect the construction thereof.</li>
      </ol>
    </p>


    <h2>Class Action/Jury Trial Waiver</h2>
    <p>WITH RESPECT TO ALL PERSONS AND ENTITIES, REGARDLESS OF WHETHER THEY HAVE OBTAINED OR USED THE SERVICES FOR PERSONAL, COMMERCIAL OR OTHER PURPOSES, ALL CLAIMS MUST BE BROUGHT IN THE PARTIES’ INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS ACTION, COLLECTIVE ACTION, PRIVATE ATTORNEY GENERAL ACTION OR OTHER REPRESENTATIVE PROCEEDING. THIS WAIVER APPLIES TO CLASS ARBITRATION, AND, UNLESS WE AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS. YOU AGREE THAT, BY ENTERING INTO THIS AGREEMENT, YOU AND ALDEN ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION, COLLECTIVE ACTION, PRIVATE ATTORNEY GENERAL ACTION, OR OTHER REPRESENTATIVE PROCEEDING OF ANY KIND.</p>

    
    </LongContent>
  );
}

export default LegalTerms;
