import React from "react";
import Container from "@material-ui/core/Container";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

const useStyles = makeStyles((theme) => ({
  accordion: {
    // Remove shadow
    boxShadow: "none",
    "&:before": {
      // Remove default divider
      display: "none",
    },
    // Add a custom border
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  expanded: {
    margin: `0 !important`,
  },
  summary: {
    minHeight: 78,
  },
  summaryContent: {
    margin: "0 !important",
  },
}));

function FaqSection(props) {
  const classes = useStyles();

  const items = [
    {
      question: "How is this different from other wellness efforts I’ve tried?",
      answer:
        `Tuesday used objective data - not self reported measures - to communicate a dependable wellness KPI. 
        
Using self-reported measures doesn’t work because people don’t always know how to articulate exactly how they feel. And they can’t predict how they will feel tomorrow. 
        
Tuesday neurologically measures both - so you can depend on the data to take action that will make change. 
        `,
    },
    {
      question: "How do I get my employees / teams / patients to participate? ",
      answer:
        `Tuesday empowers individual users by showing them real-time neurologic scores on their emotional wellness. We find that people are excited to be able to quantify their moods and emotions! 

People love to measure what they love. They will receive metrics that quantify their experiences and helpful guidance from AI-informed insights on best practices to improve their scores over time. 
        
People are compelled by the idea that they can take back control of their mental fitness and excited to participate. 
        
While sharing data is completely up to the user, there are high adoption rates to share scores to incentivize improvements in workplace, team environments or care.         
        `,
    },
    {
      question: "How does Tuesday work?",
      answer:
        `Twenty years ago, the founders of Immersion Neuroscience - the science that powers Tuesday - started researching neurochemicals - like oxytocin & dopamine that indicate emotional states of love and trust.
        
They learned these chemicals were measurable without blood draws - by observing the effects they had on the vagus nerve that were traceable through heart rate monitors. So we wrote some very sophisticated algorithms and did even more neuroscience to use smartwatches and fitness devices to measure these physical changes.
        
The brain can’t lie. So when we measure that these neurochemicals are present - we measure what we love.
        
Using this unique measure of immersion and psychological safety that Immersion discovered, Tuesday can accurately measure what you love and answer questions like:
        
        → What do I really love?
        → How is my emotional fitness today? And how is the emotional fitness of the people I care about?
        → What can I do to improve it?
        → Am I creating enough happiness for myself everyday?
        → Do I have enough emotional fitness/energy to make a big decision or should I wait until tomorrow?
        → Is the stress I am experiencing good for me because I love what I’m doing?
        → What actions or activities actually make a difference to my mental state?
`,
    },
    {
      question: "What about data privacy?",
      answer:
        "Your data belongs to you. We do not share or sell your data. If you want to share your emotional fitness with others, that is up to you. Read our detailed privacy policy for all the nitty-gritty.",
    },
    {
      question: "How is this different from measuring 'stress' or 'steps'?",
      answer:
        `Staying healthy is super important. But a happy life is not the sum of our steps, or sleep, or stress.
        
We are the sum of our experiences.
        
Low stress does not mean you have found love. And a faster heart rate doesn't mean you are happy. Unfortunately, there are plenty of skinny people that are sad, and many daily meditators that are lonely. We measure what your brain loves, and that is what matters.
`,
    },
    {
      question: "Do people need an Apple Watch or Whoop?",
      answer:
        `Mostly. 
        
Today, Tuesday works on an iPhone with an Apple Watch, Whoop device, and some Bluetooth fitness sensors. 

We will add support for Android and other smart watches over time.`,
    },
    
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="md">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />

        {items.map((item, index) => (
          <Accordion
            classes={{
              root: classes.accordion,
              expanded: classes.expanded,
            }}
            key={index}
          >
            <AccordionSummary
              classes={{
                root: classes.summary,
                content: classes.summaryContent,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`faq-panel-${index}`}
            >
              <Typography variant="h6">{item.question}</Typography>
            </AccordionSummary>
            <AccordionDetails id={`faq-panel-${index}`}>
              <Typography display="block">{item.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </Section>
  );
}

export default FaqSection;
