import React, { useState, useEffect } from "react";
import { InfluxDB } from "@influxdata/influxdb-client";
import { ResponsiveLine } from "@nivo/line";
import { ResponsiveStream } from '@nivo/stream'
import TextField from '@material-ui/core/TextField';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

//const moment = require('moment');

//console.log(process.env.REACT_APP_INFLUX_URL);

const token = process.env.REACT_APP_INFLUX_TOKEN;
const org = process.env.REACT_APP_INFLUX_ORG;
const bucket = process.env.REACT_APP_INFLUX_BUCKET;
const url = process.env.REACT_APP_INFLUX_URL;




export const TimelineChart = (props) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const { startRange, aggWindow, groupId, legend, downloadCallback, ...otherProps } = props;

  let query = `from(bucket: "${bucket}")
  |> range(start: ${startRange})
  |> filter(fn: (r) => r["_measurement"] == "score")
  |> filter(fn: (r) => r["_field"] == "e" or r["_field"] == "s")
  |> filter(fn: (r) => r["gid"] == "${groupId}")
  |> group(columns: ["_measurement", "_field", "gid"])
  |> aggregateWindow(every: ${aggWindow}, fn: mean, createEmpty: true)
  |> pivot(rowKey: ["_time"], columnKey: ["_field"], valueColumn: "_value" )
  |> yield(name: "mean")`;


  console.log(query);

  useEffect(() => {
    const interval = setInterval(() => {
      loadPageData();
    }, 60000);

    return () => clearInterval(interval);
  }, []);



  useEffect(() => {
    loadPageData();
  }, [startRange]);


  const loadPageData = () => {
    console.log("loadPageData");
    let res = [];
    const influxQuery = async () => {
      //create InfluxDB client
      const queryApi = await new InfluxDB({ url, token }).getQueryApi(org);
      //console.log(queryApi);

      //make query
      await queryApi.queryRows(query, {
        next(row, tableMeta) {
         //  console.log("a");
          const o = tableMeta.toObject(row);
         //push rows from query into an array object
          res.push(o);
        },
        complete() {
          

          let tmpDownloadDataset = [] 

          let finalData = []
          let engagement = []
          let safety = []
 
        //  console.log(res);
          //variable is used to track if the current ID already has a key
          var exists = false

          for(let i = 0; i < res.length; i++) {
            //let d = moment(new Date(res[i]['_time'])).format("YYYY-MM-DD HH:mm:ss")
            //let jd = new Date(res[i]['_time'])
            engagement.push({ "x": res[i]['_time'], "y" : res[i]['e'] });
            safety.push({ "x" : res[i]['_time'], "y" : res[i]['s'] });

            tmpDownloadDataset.push({"date": res[i]['_time'], "immersion": res[i]['e'], "safety": res[i]['s']});

            
          }

          finalData.push({"id": "Engagement", "data" : engagement});
          finalData.push({"id": "Safety", "data" : safety});

        //  console.log(finalData);

          setData(finalData);
          //setDownloadData(tmpDownloadDataset);
          downloadCallback(tmpDownloadDataset, startRange);
      
        },
        error(error) {
          console.log("query failed- ", error);
        }
      });
     
    };

    influxQuery();

  }


  return (


<>

{ /* <TextField
    id="datetime-local"
    label="Next appointment"
    type="datetime-local"
    defaultValue="2017-05-24T10:30"
    className={classes.textField}
    InputLabelProps={{
      shrink: true,
    }}
  /> */}
  
  <ResponsiveLine 
  data={data}
  margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
  curve="natural"
  enableArea={true}
  axisBottom={{
    "orient": "bottom",
    "tickSize": 0,
    "tickPadding": 5,
    "tickRotation": 0,
    "format": () => '',
    "legend": legend,
    "legendOffset": 10,
    "legendPosition": 'middle'
  }}
  yScale={{
    type: 'linear',
    min: 0,
    max: 100,
    stacked: false,
    reverse: false
}}
  lineWidth={5}
  pointSize={0}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
  legends={[
    {
        anchor: 'bottom-right',

        direction: 'column',
        justify: false,
        translateX: 100,
        translateY: 0,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 80,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: 'circle',
        symbolBorderColor: 'rgba(0, 0, 0, .5)',
        effects: [
            {
                on: 'hover',
                style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1
                }
            }
        ]
    }
    ]}
/>




</>


  )
};