import React from "react";
import Meta from "./../components/Meta";
import PricingSection from "./../components/PricingSection";
import Footer from "./../components/Footer";

function PricingPage(props) {
  return (
    <>
      <Meta title="Pricing" />
      <PricingSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Pricing"
        subtitle="Choose the plan that makes sense for you. All plans include a 14-day free trial."
      />

<Footer
                bgColor="default"
                size="medium"
                bgImage=""
                bgImageOpacity={1}
                description="Tuesday measures what you love, so you can make more of it. Every day."
                copyright={`© ${new Date().getFullYear()} Immersion Neuroscience, Inc. All Rights Reserved.`}
                logo="./images/tuesday_enterprise_logo_dark.svg"
                logoInverted="./images/tuesday_enterprise_logo_light.svg"
                sticky={true}
              />

    </>
  );
}

export default PricingPage;
