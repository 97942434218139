import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Newsletter from "./Newsletter";
import HubspotForm from "react-hubspot-form";


function NewsletterSection(props) {
  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Grid
          container={true}
          alignItems="center"
          justifyContent="center"
          spacing={4}
        >
          <Grid item={true} xs={12} md={6}>
            <SectionHeader
              title={props.title}
              subtitle={props.subtitle}
              size={4}
            />
          </Grid>
          <Grid item={true} xs={12} md={6}>


          <HubspotForm
   portalId='3351742'
   region='na1'
   formId='07339c40-ad6f-4877-917c-e22ea24aa0e0'
   onSubmit={() => console.log('Submit!')}
   onReady={(form) => console.log('Form ready!')}
   loading={<div>Loading...</div>}
   />


          </Grid>
        </Grid>
      </Container>
    </Section>
  );
}

export default NewsletterSection;
