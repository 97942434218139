import React, { useState, useEffect } from "react";
import { InfluxDB } from "@influxdata/influxdb-client";
import { ResponsiveLine } from "@nivo/line";
import { Timestamp } from "firebase/firestore";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Link, useRouter } from "./../util/router";
import { useAuth } from "./../util/auth";
//const { Configuration, OpenAIApi } = require("openai");
import OpenAI from 'openai';

const useStyles = makeStyles((theme) => ({
    headline: {
      fontWeight: "800",
      //color: "#ffffff",
    },
    paper: {
        padding: '6px 16px',
      },
      secondaryTail: {
        backgroundColor: theme.palette.secondary.main,
      },
  }));

  /*const configuration = new Configuration({
    apiKey: process.env.REACT_APP_OPENAI_KEY,
  });*/

  //const openAi = new OpenAIApi(configuration);
  const openAi = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_KEY,
    dangerouslyAllowBrowser: true, 
  });



//console.log(configuration);
//console.log(openAi);

  export const ShareRequestResultsSummary = (props) => {
    const classes = useStyles();
    const auth = useAuth();
    const router = useRouter();

    const { immersion, safety, attendeeCount, ...otherProps } = props;

    const [summaryResponse, setSummaryResponse] = useState("");
    const [loading, setLoading] = useState(false);


    useEffect(() => {
      console.log("useEffect loadSummary");
        loadSummary(Math.round(immersion), Math.round(safety), attendeeCount);
      }, [immersion, safety]);

    const loadSummary = (immersion, safety, attendeeCount) => {
       
        const openAiQuery = async () => {
        try {
            setLoading(true);
            const prompt = `Immersion is measured on a scale of 0-100, Psychological Safety is measured on a scale of 1-100. 
            The higher a persons Immersion the more likely they are to remember and act on an experience.  
            The higher a persons Psychological Safety, the more likely they are to absorb and engage 
            in an experience. How would you describe an experience where a group of people had a 
            combined Immersion score of ${immersion} and a Psychological Safety score of ${safety}? Additionally, in order to have a robust and 
            repeatable result the experience should have more than 35 participants. In this case, the experience had ${attendeeCount} participants. 
            Based on the number of participants, please rank your confidence in these results like a letter grade between A-C. You should 
            call the group of people that had this experience 'participants'. Always recommend that that they run the test again with more participants. 
            Always recommend that they download the full report to get the second-by-second detail.`;

            console.log("loadSummary");
            console.log(prompt);

            const result = await openAi.chat.completions.create({
              messages: [{ role: 'user', content: prompt }],
              model: 'gpt-3.5-turbo',
              temperature: 0.7,
              max_tokens: 2500,
            });

            console.log(result);

            /*const result = await openAi.createCompletion({
              model: "text-davinci-003",
              prompt: prompt,
              temperature: 0.7,
              max_tokens: 2500,
            });*/
            console.log("response", result);

            console.log("Summary Result", result.choices[0].message.content.trim());

            setSummaryResponse(result.choices[0].message.content.trim());
          } catch (e) {
            //console.log(e);
            setSummaryResponse("Something is wonky with the AI, please try again.");
          }
          setLoading(false);
        }
        
        openAiQuery();


      }

      return (

        <Typography variant={`body1`} color="primary" gutterBottom={false} >
            {loading ? "Generating experience summary..." : ""}
            { summaryResponse }
        </Typography>
      )

  };