import React from "react";
import Meta from "./../components/Meta";
import HeroSection2 from "./../components/HeroSection2";
import StatsSection from "./../components/StatsSection";
import TeamBiosSection from "./../components/TeamBiosSection";
import CtaSection from "./../components/CtaSection";
import Footer from "./../components/Footer";
import ContentSectionImageRight from "./../components/ContentSectionImageRight";
import ContentSectionImageLeft from "./../components/ContentSectionImageLeft";


function AboutPage(props) {
  return (
    <>
      <Meta title="About" description="Learn about our company and team" />
      <HeroSection2
        bgColor="primary"
        size="large"
        bgImage="./images/brain-header.jpg"
        bgImageOpacity={0.4}
        title="Real Science. Real Results."
        subtitle="This team spent 20 years figuring out how to measure what people love. And our customers have used it to build happier and more productive teams; ensure patient compliance and wellness; and get objective KPI’s to create effective, lasting improvements for emotional fitness."
      />
      <StatsSection
        bgColor="light"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
      />


<ContentSectionImageLeft
        bgColor=""
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="About Tuesday"
        bodyText={`Tuesday - an app powered by Immersion Neuroscience measures what people love using the smartwatch they already wear. 

        The app delivers daily scores & insights to users about what brings them connection and joy for happier, healthier lives. 
        
        When users share this information, leaders and social connections can identify high and low moments so they can improve happiness or intervene when needed. 
        
        Tuesday identifies your moments of peak connection so you can measure what you love and focus on creating more of it.
        
        Tuesday can also predict downturns in emotional fitness up to two days in advance so you can determine when you need to slow down or step back.
         `}
        subtitle="All the features you need to move faster"
        mainImage="/images/tuesday_girl_01.jpg"
        buttonText="Learn More"
        buttonColor="secondary"
        buttonPath="/contact"
      />



      <CtaSection
        bgColor="primary"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Ready to get started?"
        subtitle=""
        buttonText="Get Started"
        buttonColor="default"
        buttonPath="/pricing"
      />

<Footer
                bgColor="default"
                size="medium"
                bgImage=""
                bgImageOpacity={1}
                description="Tuesday measures what you love, so you can make more of it. Every day."
                copyright={`© ${new Date().getFullYear()} Immersion Neuroscience, Inc. All Rights Reserved.`}
                logo="./images/tuesday_enterprise_logo_dark.svg"
                logoInverted="./images/tuesday_enterprise_logo_light.svg"
                sticky={true}
              />


    </>
  );
}

export default AboutPage;
