import React from "react";
import Container from "@material-ui/core/Container";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Contact from "./Contact";
import HubspotForm from "react-hubspot-form";



function ContactSection(props) {
  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="md">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />

<HubspotForm
   portalId='3351742'
   region='na1'
   formId='6b51ca55-1fda-4387-9265-29ef6259e4c7'
   onSubmit={() => console.log('Submit!')}
   onReady={(form) => console.log('Form ready!')}
   loading={<div>Loading...</div>}
   />


      </Container>
    </Section>
  );
}

export default ContactSection;
