import React from "react";
import LongContent from "./LongContent";

function LegalPrivacy(props) {
  return (
    <LongContent>
      <h1>Privacy Policy</h1>
      <p>
      This policy describes the ways Immersion Neuroscience, Inc. (hereinafter, “Immersion”, “Tuesday”, “we”, “our” or “us”) collect, store, use and protect your data. The purpose of this policy is to ensure that Tuesday provides you notice of our data collection and use policies. Capitalized terms not defined in this Privacy Policy shall have the meaning set forth in the Terms of Service.
      </p>

      <h2>PRIVACY IS IMPORTANT TO US</h2>
      <p>Your privacy is important to us and this policy provides an overview of how we employ, disclose, and process your personal data.</p>

      <h2>HOW YOU ACCEPT THIS POLICY (PLEASE READ CAREFULLY!)</h2>
      <p>By using the Site and/or Services, you agree to the use, disclosure, and procedures outlined in this Privacy Policy.   </p>


      <h2>CHANGES TO THIS POLICY</h2>
      <p>We may amend this policy at any time by posting a revised version on our Site and/or Services. The revised version will be effective at the time we post it.</p>

      <h2>INFORMATION WE REQUEST FROM TUESDAY USERS</h2>
      <p>We may request, collect and in some cases retain certain personally identifiable information. This may include your email address and password, first and last name, gender, and date of birth, and as you use the Site and/or Services, you may provide other information about your focus, heart rate, emotional fitness activities, and mindfulness practices (collectively, “Personal Information”).  </p>

      <p>You will also have an option to permit us to import into the Site and/or Services data from third-party services such as Apple HealthKit, among others. We will not use the information gained through your use of the HealthKit framework for advertising or similar services, or sell it to advertising platforms, data brokers, or information resellers. You expressly consent that we may only share such information to a third party if they are also providing a health or fitness service to you, or for medical research purposes, or for other purposes specified in this Privacy Policy and permitted under applicable agreements governing the use of Apple HealthKit framework. Data read from the Apple HealthKit database may be used to perform calculations related to your heart rate, activity and other factors designed to inform the Site and/or the Services with regard to Mindfulness. Please consult your device documentation on how to manage local storage and how to apply appropriate security controls to your device for the protection of information. Tuesday is in no way responsible for the protection of data and information stored within the Apple HealthKit database. It is strongly recommended that you review Apple’s applicable policies and procedures before synching and backup your Apple HealthKit data.</p>

      <h2>INFORMATION THAT IS AUTOMATICALLY COLLECTED BY TUESDAY</h2>
      <p>Information necessary to provide the Services:
        <ul>

<li>Personal Information derived from your mobile or wearable device, including but not limited to the following data from your device’s sensors: ECG, heart rate, heart rate variability, accelerometer and movement data.</li>
<li>Date and time information you create through the Site and/or the Services.</li>
<li>Information automatically collected about the device used to access the Site and/or the Services (such as, but not limited to, device identifiers, hardware, operating system, browser, etc.).</li>
<li>Communications: Information that you provide to us in correspondence, including with respect to ongoing user support.</li>
<li>Location and Geolocation Information: Information automatically collected to determine your location, including your IP address and/or domain name.</li>
<li>Log Data: Information that is generated by your use of the Site and/or the Services that is automatically collected and stored in our systems and within our server logs (including but not limited to geo-location information, unique device information, system activity and an information related to the Services you use.</li>
<li>Various analytics data, such as: (i) the IP address of the computer or device you use to access the Site and/or the Services; (ii) the type of browser software you are using; (iii) the operating system you are using; (iv) the date and time you access or use Site and/or the Services; (v) the website address, if any, that linked you to the Services; (vi) the website address, if any, you leave our Site and travel to; and (vii) other non-personally identifiable traffic data.</li>
</ul>
</p>

      <h2>HOW TUESDAY USES YOUR PERSONAL INFORMATION </h2>
      <p>Personal Information is data that can be used to identify you directly or indirectly or to contact you. Our primary purpose for collecting personal information is to provide you with a secure, smooth, efficient, and effectively personalized service and user experience.</p>

      <p>
      <ul>
        <li>We collect Personal Information to provide you with our Site and/or Services. When we require certain personal information from users it is because we are required by the applicable law to collect this information or it is relevant for specified purposes.</li>
        <li>The personal information we request is either essential or optional in nature. Without essential Personal Information, we will not be able to offer you our Services at all. While you are free to choose whether to provide us with the optional Personal Information that we request, we may not be able to serve you as effectively or offer you all of our Services when you do choose not to share that optional Personal Information.</li>
        <li>•	For example, we collect personal information to (i) comply with applicable laws and regulations; (ii) provide the Services and customer support you request; (iii) process transactions and send notices about your transactions; (iv) resolve disputes, collect fees, and troubleshoot problems; (v) prevent potentially prohibited or illegal activities, and enforce our Terms of Service; (vi) customize, measure, and improve the Services and its content and applications; (vii) deliver targeted marketing, service update notices, and promotional offers based on your communication preferences; compare information for accuracy and verify it with third parties; and (viii) for scientific and academic research purposes.</li>
      </ul>

      </p>


      <h2>THIRD PARTY PRIVACY POLICIES</h2>
      <p>Users of our Services may access third party websites and or utilize third party services that are packaged within or supplied by and through the Services, subject to the terms and conditions and privacy policies of such third parties. Users acknowledge and agree that Tuesday is not responsible for any third party terms and conditions, nor for privacy policies and use of information by such third parties. Among others, we may share your Personal Information with the following third-party services: Apple and Amazon. </p>

      <h2>COOKIES</h2>
      <p>When you access or use the Service, we may use industry-wide technologies such as “cookies” or similar technologies, which stores certain information on your computer and which will allow us to enable automatic activation of certain features, and make your Service experience much more convenient and effortless. We may also use certain third-party tracking technologies. These are a different kind of cookies, stored on your computer by third parties, rather than by us. This kind of tracking technology is deployed or used each time you visit the Service, and also when you visit certain websites or applications that use similar cookies.</p>

      <h2>MARKETING</h2>
      <p>We do not sell your personal data to third parties for their marketing purposes without your explicit consent. We may combine your information with information we collect from other companies and use it to improve and personalize the Services, content and advertising. If you do not wish to receive marketing communications from us or participate in our ad programs, you can send an email to hello@besttuesdayever.com</p>

      <h2>HOW WE PROTECT YOUR INFORMATION</h2>
      <p>We are committed to making sure your information is protected and have selected third-party vendors that help keep your personal data safe. Unfortunately, we do not control these third parties and therefore cannot guarantee complete security. We employ several commercially reasonable physical and electronic safeguards to keep your information safe where possible. Accordingly, we cannot fully guarantee against the access, disclosure, alteration, or deletion of data through events, including but not limited to hardware or software failure or unauthorized use. Any information that you provide to us is done so entirely at your own risk.</p>

      <h2>ONLINE TRACKING AND HOW WE RESPOND TO DO NOT TRACK SIGNALS</h2>
      <p>Online tracking is the collection of data about an individual’s Internet activity used to deliver targeted advertisements and for other purposes. Some web browsers (including Safari, Internet Explorer, Firefox, and Chrome) incorporate a “Do Not Track” (DNT) or similar feature that signals to websites that a visitor does not want to have his/her online activity and behavior tracked. If an online service elects to respond to a particular DNT signal, the service may refrain from collecting certain personal information about the browser’s user. Not all browsers offer a DNT option and there is currently no industry consensus as to what constitutes a DNT signal. For these reasons, many website operators, including Tuesday, do not take action to respond to DNT signals. </p>

      <h2>COOPERATION WITH LAW ENFORCEMENT </h2>
      <p>We reserve the right to access, read, preserve, and disclose any information that we reasonably believe is necessary to: comply with the law or a court order; cooperate with law enforcement; enforce or apply our Terms of Service and other agreements; or protect the rights, property, or safety of Tuesday, our employees, our users, or others.</p>

      <h2>BUSINESS TRANSFERS</h2>
      <p>We may choose to buy or sell assets. In these types of transactions, company data is a business asset that would be transferred. Also, if we (or our assets) are acquired, or if we go out of business, enter bankruptcy, or go through some other change of control, company data which may include your personal information could be one of the assets transferred to or acquired by a third party. By accepting this Privacy Policy, as outlined above, you consent to any such transfer.</p>

      <h2>DATA RETENTION</h2>
      <p>If you delete your data from any of the Services including but not limited to any mobile applications, uninstall any Tuesday software applications including but not limited to any mobile applications from your device, or request that your information be deleted, we still may retain some information that you have provided to us to maintain Tuesday or to comply with relevant laws.</p>

      <h2>AGGREGATED PERSONAL DATA AND NON-PERSONAL INFORMATION</h2>
      <p>We may share or disclose aggregated personal data or non-personal information with service providers or with other persons we conduct business with, including but not limited potential third-parties for the purpose of showcasing the performance of the Services. These service providers and other persons may also share with us aggregated non-personal information that they have independently developed or acquired. Additionally, we may combine aggregate information from the pixel tags, web beacons, and cookies with similar data we collect from other visitors to help us improve our Services. When doing so, we use commercially reasonable efforts to ensure that the any aggregated information cannot be linked back to you.</p>

      <h2>YOUR RIGHTS UNDER THE GENERAL DATA PROTECTION REGULATION (“GDPR”)</h2>
      <p>Tuesday is hosted in the United States. If you are a user accessing the Services from the European Union, Asia, or any other region with laws or regulations governing personal data collection, use, and disclosure that differ from United States laws, please be advised that through your continued use of the Services, which is governed by US law, you are transferring your Personal Information to the United States and you consent to that transfer.</p>

      <p>EU and Swiss residents: Please bear in mind that we may transfer your Personal Information to the United States where data protection is not deemed adequate under applicable data protection law.</p>

      <p>Nonetheless, Tuesday undertakes to respect the confidentiality of your Personal Information and to use reasonable efforts to assure that you can exercise your rights. Accordingly, you have the right under this Privacy Policy, and by law if you are within the EU, to:

      <ul>
        <li>Request access to your Personal Information. The right to access, update or delete the information Tuesday may have in connection with you by contacting Tuesday to assist you. This also enables you to receive a copy of the Personal Information Tuesday holds about you.</li>
        <li>Request correction of the Personal Information that Tuesday has about you. You have the right to have any incomplete or inaccurate information Tuesday has about you corrected.</li>
        <li>Object to processing of your Personal Information. This right exists where Tuesday is relying on a legitimate interest as the legal basis for our processing and there is something about your situation, which makes you want to object to our processing of your Personal Information on this ground. You also have the right to object where Tuesday is processing your Personal Information for direct marketing purposes.</li>
        <li>Request erasure of your Personal Information. You have the right to ask Tuesday to delete or remove Personal Information when there is no good reason for Tuesday to continue processing it.</li>
        <li>Request the transfer of your Personal Information. Tuesday will provide to you, or to a third-party you have chosen, your Personal Information in a structured, commonly used, machine-readable format. Please note that this right only 
applies to automated information which you initially provided consent for Tuesday to use or where Tuesday used the information to perform a contract with you.
</li>
        <li>Withdraw your consent. You have the right to withdraw your consent on using your Personal Information. If you withdraw your consent, Tuesday may not be able to provide you with access to certain specific functionalities of the Services.</li>
        <li>NOTE: Tuesday will use reasonable efforts to comply with all of the above requests if technically feasible and you understand that if Tuesday complies with any such requests, <i>Tuesday may not be able to provide you with access to certain specific functionalities of the Services.</i></li>

      </ul>
      </p>



      <h2>EXERCISING OF YOUR GDPR DATA PROTECTION RIGHTS</h2>
      <p>You may exercise your rights of access, rectification, cancellation and opposition by contacting Tuesday. Please note that we may ask you to verify your identity before responding to such requests. If you make a request, Tuesday will try our best to respond to you as soon as possible.</p>
      <p>You have the right to complain to a Data Protection Authority about our collection and use of your Personal Information. For more information, if You are in the European Economic Area (EEA), please contact Your local data protection authority in the EEA.</p>


      <h2>CALIFORNIA CONSUMER PRIVACY ACT (“CCPA”) PRIVACY–YOUR RIGHTS UNDER THE CCPA</h2>
      <p>Under this Privacy Policy, and by law if you are a resident of California, you have the following rights:

        <ul>
        <li>The right to notice. You must be properly notified which categories of Personal Information are being collected and the purposes for which the Personal Information is being used.</li>
        <li>The right to access / the right to request. The CCPA permits you to request and obtain from Tuesday information regarding the disclosure of your Personal Information that has been collected in the past 12 months by Tuesday or its subsidiaries to a third-party for the third party’s direct marketing purposes.</li>
        <li>The right to know about Your Personal Information. You have the right to request and obtain from Tuesday information regarding the disclosure of the following:
            <ul>
              <li>The categories of Personal Information collected.</li>
              <li>The sources from which the Personal Information was collected.</li>
              <li>The business or commercial purpose for collecting or selling the Personal Information.</li>
              <li>Categories of third parties with whom We share Personal Information</li>
              <li>The specific pieces of Personal Information we collected about you.</li>
            </ul>

        </li>
        <li>The right to delete Personal Information. You also have the right to request the deletion of your Personal Information that have been collected in the past 12 months.</li>
        <li>The right not to be discriminated against. You have the right not to be discriminated against for exercising any of your consumer’s rights, including by:
            <ul>
              <li>Denying goods or services to you.</li>
              <li>Charging different prices or rates for goods or services, including the use of discounts or other benefits or imposing penalties.</li>
              <li>Providing a different level or quality of goods or services to you.</li>
              <li>Suggesting that You will receive a different price or rate for goods or services or a different level or quality of goods or services.</li>
            </ul>

        </li>

        </ul>

      </p>


        <h2>EXERCISING YOUR CCPA DATA PROTECTION RIGHTS</h2>
        <p>In order to exercise any of your rights under the CCPA, if you are a California resident, you can email or call us. NOTE: Tuesday will use reasonable efforts to comply with all of the above requests if technically feasible and you understand that if Tuesday complies with any such requests, Tuesday may not be able to provide you with access to certain specific functionalities of the Service.</p>
        <p>Tuesday will disclose and deliver the required information free of charge within 45 days of receiving your verifiable request. The time period to provide the required information may be extended once by an additional 45 days when reasonably necessary and with prior notice.</p>


        <h2>DO NOT SELL MY PERSONAL INFORMATION</h2>
        <p>We do not sell Personal Information. However, the service providers we partner with (for example, our technology infrastructure and analytics partners) may use technology on the Services that “sells” personal information as defined by the CCPA law.</p>

        <h2>QUESTIONS ABOUT PRIVACY? PLEASE CONTACT US.</h2>
        <p>If you have questions concerning this Privacy Policy, please feel free to send us an email at hello@besttuesdayever.com. </p>

       
      <p>This policy was last updated on May 22, 2023.</p>
      
    </LongContent>
  );
}

export default LegalPrivacy;
