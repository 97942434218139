import React from "react";
import Meta from "./../components/Meta";
import DashboardSection from "./../components/DashboardSection";
import ResearchDashboardSection from "./../components/ResearchDashboardSection";
import { requireAuth } from "./../util/auth";
import { useAuth } from "./../util/auth";
import { useTeam } from "../util/db";


function DashboardPage(props) {
  const auth = useAuth();
  const { data: team, status: teamStatus, error: teamError,} = useTeam(auth.user.team_id);


  return (
    <>
      <Meta title="Dashboard" />

      {team && team.type === 'enterprise' && (

        <DashboardSection
          bgColor="#ffffffff"
          size="normal"
          bgImage=""
          bgImageOpacity={1}
          title="Your Team"
          subtitle=""
        />
      )}

      {team && team.type === 'research' && (

        /*<!-- make this the research dashboard -->*/
        <ResearchDashboardSection
          bgColor="#ffffffff"
          size="normal"
          bgImage=""
          bgImageOpacity={1}
          title="Your RESEARCH Team"
          subtitle=""
        />
      )}
        


    </>
  );
}

export default requireAuth(DashboardPage);
